import React from 'react'
import {
    EditorFormActions,
    Typography,
    Stack,
    FluidForEditors
} from 'components'

import { TextField } from '@material-ui/core';
import { FormikTextField, Form, Formik, Yup } from 'components/FormikField';

import { FormikProps } from 'formik';
import { BusinessUnitSelectField } from 'Dictionary/component';

import * as Model from '../model';
import { SignerSelectField } from './SignerSelectField';
import { ImageField } from './ImageField';

type DTOModel = Model.DigitalSignatureDto

export default function Editor(p: {
    item: DTOModel

    onCancel: React.DispatchWithoutAction
    onCommit: React.Dispatch<DTOModel>
}) {

    const x = p.item
    const toEdit = {
        ...x,
        divisions: x.divisions!.map(x => ({ ...x, name: x.description }))
    } as DTOModel

    ///
    ///
    const onCancelAdd = () => p.onCancel()
    const onUpdateRow = (item: DTOModel) => p.onCommit(item)

    ///
    ///
    function renderForm(formikProps: FormikProps<DTOModel>) {
        const fh = formikProps.getFieldMeta("signature")
        const editing = !!formikProps.values.id;
        return (
            <Form>
                <FluidForEditors inRow={2}>
                    <>
                        {editing ? <TextField name="signer" value={formikProps.values.signer.email} disabled /> : <SignerSelectField />}
                    </>                    
                    <BusinessUnitSelectField name="divisions" multiple required />
                    <FormikTextField multiline name="labelSignature" label="Signature label" rows={4} inputProps={{ style: { paddingTop: 10 }}} />
                    <Stack>
                        <Typography variant='caption'>Signature</Typography>
                        <ImageField fieldName='signature' />
                    </Stack>
                </FluidForEditors>
                <EditorFormActions isNew={!editing} formik={formikProps} onCancelAdd={onCancelAdd} />
            </Form>
        )
    }

    const formValidationSchema = Yup.object().shape({
        divisions: Yup.array().min(1, 'This field is required'),
        signer: Yup.object().nullable().required('This field is required'),
        signature: Yup.string().required(),
        labelSignature: Yup.string().required('This field is required'),
    });

    if (!toEdit) return (<></>)
    return (<>
        <Formik
            initialValues={toEdit}
            validationSchema={formValidationSchema}
            onSubmit={onUpdateRow}
        >
            {(formikProps) => renderForm(formikProps)}
        </Formik>
    </>)
}
