import React from 'react'
import {
  DataTableColumn,
  IconButton,
  UsedIcons,
  useToasts
} from 'components'

import * as route from 'route';

import { Menu, MenuList, MenuItem } from '@material-ui/core';
import { AbbColor, StatusChip } from 'components/StatusChip';
import { formatDate } from 'utils';
import {
  API,
  CertificateListDefinitionDTO,
  CertificateListDefinitionFindQuery,
  CertificateListLookupDTO,
  KnownCertificateListStatus
} from './model';
import { PageBase, RowContext } from '../../../components/Page/PageBase';

//import Editor from './Editor'
import FiltersPart from './Filters'
// eslint-disable-next-line
function Editor(p: any) {
  return <>EDITOR</>
}


type QueryModel = CertificateListDefinitionFindQuery
type DTOModel = CertificateListLookupDTO

const defaulQuery: QueryModel = {
  purpose: "Manage",
  sortExpression: "publishDate",
  sortDescending: true,
  currentPageIndex: 0,
  pageSize: 50
}

const StatusColors: { [key: string]: AbbColor } = {
  "Published": 'green',
  "Other": 'red'
}

const cols: DataTableColumn<DTOModel>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 350,
  },
  {
    field: 'division',
    headerName: 'Division',
    renderField: (r) => r.division?.display
  },
  {
    field: 'owner',
    headerName: 'Owner',
    renderField: (r) => r.owner ? `${r.owner.firstName} ${r.owner.lastName}` : ''
  },
  {
    field: 'release',
    headerName: 'Release'
  },
  {
    field: 'level',
    headerName: 'Level',
    renderField: (r) => r.level?.display
  },
  {
    field: 'publishDate',
    headerName: 'Published on',
    renderField: ({ publishDate }) => formatDate(publishDate)
  },
  {
    field: 'status',
    headerName: 'Status',
    renderField: (r) => <StatusChip label={r.status.display} color={StatusColors[r.status.code]} />
  },
]

function ContextMenu(p: RowContext<DTOModel>, showError: (error: string) => void) {
  const { item } = p

  return (<MenuList>
    <MenuItem onClick={e => p.ports.doGoToDetails({ item, newWindow: e.ctrlKey })}>Edit</MenuItem>
    <MenuItem onClick={() => API.ICertificateListContract
      .Withdraw(item)
      .then(p.ports.doRefreshList)}>Withdraw</MenuItem>
    <MenuItem onClick={() => API.ICertificateListContract
      .Copy(item)
      .then(x => p.ports.doGoToDetails({ item: { id: x.itemID } as DTOModel, newWindow: false }))
      .catch(e => showError(e.message ? `Cannot create copy. Additional message: ${e.message}` : "Cannot create copy."))}>Create copy</MenuItem>
    {(item.status.code !== KnownCertificateListStatus.Draft) && 
      <><MenuItem onClick={() => API.ICertificateListContract
        .NewVersion(item)
        .then(x => p.ports.doGoToDetails({ item: { id: x.itemID } as DTOModel, newWindow: false }))
        .catch(e => showError(e.message ? `Cannot create new version. Additional message: ${e.message}` : "Cannot create new version."))}>Add new version</MenuItem>
      <MenuItem onClick={() => API.ICertificateListContract
        .NewSubVersion(item)
        .then(x => p.ports.doGoToDetails({ item: { id: x.itemID } as DTOModel, newWindow: false }))
        .catch(e => showError(e.message ? `Cannot create new subversion. Additional message: ${e.message}` : "Cannot create new subversion."))}>Add new subversion</MenuItem></>
    }
  </MenuList>)

}

export function Page() {
  const { showError } = useToasts();
  const [anchorEl, setAnchorEl] = React.useState<null | { target: HTMLElement, menu: JSX.Element }>(null);


  const list = PageBase<CertificateListDefinitionDTO, CertificateListDefinitionFindQuery>({
    defaultQuery: defaulQuery,
    getIdFor: (item) => item.id,
    contract: API.ICertificateListContract,

    pageSettings: {
      title: "Manage certificates",
      subtitle: "Here you can start working on certificate list",
      details: {
        route: (item) => route.manageCertificatesDetailsRoute.createRoute({ itemId: item.id.toString() })
      },
      newItem: {
        actionCaption: "ADD CERTIFICATE",
        route: () => route.manageCertificatesDetailsRoute.createRoute({ itemId: "0" })
      },
      rowActionComponent: (p) => <>
        <IconButton
          onClick={(e) => { setAnchorEl({ target: e.currentTarget, menu: ContextMenu(p as unknown as RowContext<DTOModel>, showError) }) }}
        >
          <UsedIcons.Menu />
        </IconButton>
      </>
    },
    columns: cols,
    getRowView: (row, cancelHandler, updateHandler) => (<Editor id={row?.id} onCancelAdd={cancelHandler} onUpdateRow={updateHandler} />),
    getSearchFiltersForm: (onQuery) => (<FiltersPart onQuery={onQuery} defaulQuery={defaulQuery} />)
  })

  return (<>
    {list}
    <Menu
      anchorEl={anchorEl?.target}
      open={Boolean(anchorEl)}
      hidden={!anchorEl}
      keepMounted
      onClose={() => setAnchorEl(null)}
      onClick={() => setAnchorEl(null)}>
      {anchorEl ? anchorEl?.menu : undefined}
    </Menu>
  </>)
}

;