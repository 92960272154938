import { usePCSContext } from 'Router/PCSContext';
import { FieldArray } from 'formik';
import { IconButton, DataTable, UsedIcons } from 'components';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';
import { catalogDetailsRoute } from 'route';

import * as Model from './model';

type TItemModel = Model.CertificateListLookupDTO

export function CertificateLookupForField(p: { name: string; noDataMessage?: JSX.Element }) {
    const pcsContext = usePCSContext();
    const history = useHistory();

    return (<FieldArray name={p.name} >
        {arrayHelpers => {
            
            const items = arrayHelpers.form.values[arrayHelpers.name] as TItemModel[]
            function doDelete(item: TItemModel) {
                arrayHelpers.remove(arrayHelpers.form.values[arrayHelpers.name].indexOf(item));
            }

            function onDelete(item: TItemModel) {
                pcsContext!.showQuestion({
                    onConfirmed: () => doDelete(item),
                    confirmTitle: "Remove certificate from list?",
                    confirmDescription: "The certificate will be removed from list.",
                    confirmButton: "REMOVE"
                });
            }
              const handleOpenDetails = (certificationId: number) => () =>
                history.push(
                    catalogDetailsRoute.createRoute({
                    certificationId: String(certificationId),
                  })
                );
            

            return (
                <div>

                    <DataTable
                        rows={items.sort((a, b) => (a.title).localeCompare(b.title))}
                        columns={[
                            { field: "title", headerName: "Title", width: 300 },
                            { field: "division", headerName: "Division", renderField: (r) => r.division.display },
                            { field: 'release', headerName: 'Release' },
                            { field: 'level', headerName: 'Level', renderField: (r) => r.level?.display },
                            { field: 'publishDate', headerName: 'Published', renderField: ({ publishDate }) => DataTable.formatDate(publishDate) },
                        ]}
                        noDataMessage={p.noDataMessage ?? "No certificates added"}
                        renderRowActions={(item) => (<>
                            <IconButton onClick={() => onDelete(item)}>
                                <UsedIcons.Delete />
                            </IconButton>          
                            <IconButton onClick={handleOpenDetails(item.id)}>
                                <NavigateNextIcon />
                            </IconButton> </>)}
                        hideCollapseColumn />
                </div>
            )
        }}
    </FieldArray>
    );

}

