import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik'

import { usePCSContext } from 'Router/PCSContext';
import * as route from 'route';
import { useMyProfileQuery } from 'MyProfile/query';

import {
    TabsView,
    Loader,
    Content,
    useToasts,
    RoundButton,
    DetailsAppShell,
    EditorHelper,
    EditorBase,
    Yup
} from 'components'

import { AlternativesCardPart } from './AlternativesCardPart';
import { RequirementsFormPart } from './RequirementsCardPart';
import { PrintOptionsCardPart } from './PrintOptionsCardPart';
import { RecommendedCardPart } from './RecommendedCardPart';
import { PrerequisitesCardPart } from './PrerequisitesCardPart';
import { AvailabilityCardPart } from './AvailabilityCardPart';
import { HeaderDataCardPart } from './HeaderDataCardPart';
import { LifecycleCardPart } from './LifecycleCardPart';

import { API, KnownCertificateListStatus } from './model';
import * as Model from './model';
import { AutomaticEnrollmentCardPart } from './AutomaticEnrollmentCardPart';

export interface FromRouteParams {
    itemId: string;
}

type DTOModel = Model.CertificateListDefinitionDTO
type TDataModel = DTOModel
type TQueryModel = Model.CertificateListDefinitionFindQuery


export function EditorPage() {
    const { itemId } = useParams<FromRouteParams>();
    const itemIdParsed = parseInt(itemId, 10)
    return <EditorPagePart itemId={itemIdParsed} />
}

function EditorPagePart(p: { itemId: number }) {

    const [state, setState] = useState<{
        item: TDataModel
    } | undefined>()

    const history = useHistory();
    const feedback = useToasts();
    const pcsContext = usePCSContext();
    const currentUser = useMyProfileQuery().data;

    const itemIdParsed = p.itemId

    const settings: DetailsPageSettings<TDataModel, TQueryModel> = {
        contract: API.ICertificateListContract,
        newItem: () => ({
            id: 0,
            release: '1.0',
            status: { id: 0, display: "Draft", code: KnownCertificateListStatus.Temporary },
            forChannels: [],
            forCountries: [],
            forDivisions: [],
            forRegions: [],
            forUserType: { id: 0, code: "All", display: "Any" },
            behavior: { id: 1, code: "AutomaticallyEnroll", display: "Automatically enroll Prerequisites when enrolled" },
            validFor: [],
            workType: [],
            owner:{
                email: currentUser!.email,
                firstName: currentUser!.firstName,
                fullDisplayName: `${currentUser!.firstName} ${currentUser!.lastName} (${currentUser!.email})`,
                geid: currentUser!.geid,
                id: currentUser!.id,
                lastName: currentUser!.lastName,
            },
            prerequisites: [],
            scopeDetail: [],
            recommended: [],
            automaticEnrollment: [],
            alternatives: [],
            requirements: [],
            identificators: [],
            printPurposeOnPdf: false,
            history: [],
            customExpirationDate: { id: 1, code: "FIXED", display: "Fixed for all enrolled users" },
            enrollmentApproverType:  { id: 0, code: "NONE", display: "Not needed" },
            completionApproverType:  { id: 2, code: "AtomaticllyWhenAllCompleted", display: "Automatically when objects and pre-requisites completed" },
            renewalApproverType:  { id: 0, code: "NONE", display: "Not needed - Certificate does not expire" },
            renewalEnrollmentApproverType:  { id: 0, code: "NONE", display: "Not needed" },
            ownerCountry: { id: 0, code: "Global", display: "Global" },
            expirationPeriod: 0,
            renewalPeriod: 0,
            completePeriod: 0,
            renewalSurvey: {id: 0, code: "NONE", display: "Not needed"}
        }),
        validationSchema: Yup.object<Partial<Record<keyof TDataModel, Yup.AnySchema>>>({
            division: Yup.object().nullable().required(),
            businessArea: Yup.object().nullable().required(),
            scope: Yup.object().nullable().required(),
            scopeDetail: Yup.array().nullable().min(1, 'Please select at least one Scope Detail').required(),
            level: Yup.object().nullable().required(),
            mainFunction: Yup.object().nullable().required(),
            owner: Yup.object().nullable().required(),
            //purpose: Yup.string().required().min(2),
            title: Yup.string().required().min(2),
            duration: Yup.string().nullable().test("checkIsFormatCorrect", "Incorrect duration format", 
                (value) => {
                    if(value){
                        const regex=/^[0-9]{1,}:[0-5][0-9]$/;
                        return regex.test(value!);
                    }
                    return true;
                }),          
            expirationPeriod: Yup.number().when(
                ['customExpirationDate','renewalApproverType'], {
                is: (x1: DictionaryItemByCodeDTO | undefined, x2: DictionaryItemByCodeDTO | undefined) => (x1?.code === "FIXED" && x2?.code !== "NONE"),
                then: () => Yup.number().min(1, "Expiration must be greater than 0"),
            }),
            renewalPeriod: Yup.number().when(
                'renewalApproverType', {
                is: (x: DictionaryItemByCodeDTO | undefined) => (x?.code !== "NONE" && x?.code !== "NORENEWAL"),
                then: () => Yup.number().min(1, "Renewal must be greater than 0"),
            }),                
            validFor: Yup.array().required(),
            workType: Yup.array().required(),

            completionApproverType: Yup.object().nullable().required(),
            behavior: Yup.object().nullable().required(),

            forUserType: Yup.object().nullable().required(),
        }),
        pageSettings: {
            title: (item: DTOModel) => item.id > 0 ? item.title : "Define new Certificate",
            subtitle: (item: DTOModel) => "Manage Certificate",
            details: {
                routeToNew: (itemId: number) => route.managTrainingSetDetailsRoute.createRoute({ itemId })
            },
        },
    }

    function loadDataBase() {
        if (itemIdParsed === 0)
            setState(s => ({ item: settings.newItem() as TDataModel }))
        else
            settings.contract
                .get(itemIdParsed)
                .then(x => ({ ...{ alternatives: [], requirements: [], identificators: [], history: [] }, ...x} as TDataModel))
                .then(x => setState(s => ({ item: x })))
    }

    async function doUpdate(row: TDataModel) {
        const updateResult = await API.ICertificateListContract.update(row) as { itemID: number };

        try {
            if (row.id === 0) {
                //feedback.showSuccess('Certificate added... page will be refreshed')
                const targetRoute = route.manageCertificatesDetailsRoute.createRoute({ itemId: updateResult.itemID.toString() })
                history.replace(targetRoute)
                window.location.reload()
            }
            else {
                //feedback.showSuccess('Certificate updated')
                loadDataBase()
            }
        } catch (e) {
            //@ts-ignore checked
            feedback.showError((e && e.message) ? `Cannot update certificate. Additional message: ${e.message}` : "Cannot update certificate")
        }
    }
    

    function doDelete(row: TDataModel ) {
        API.ICertificateListContract.RemoveDraft(row)
            .then(e => feedback.showSuccess('Certificate removed'))
            .then(() => history.goBack())
            .catch(e => feedback.showError(e.message ? `Cannot remove certificate. Additional message: ${e.message}` : "Cannot remove certificate"))
    }

    function doRemoveDraft(row: TDataModel) { doDelete(row) }
    function doSaveAsDraft(row: TDataModel, helper:EditorHelper<TDataModel>) { 
        if(!helper.isValid || Object.keys(helper.touched).length == 0) {
            feedback.showError("Cannot save certificate. Fill all mandatory fields")
        } else {
            doUpdate({ ...row, status: { id: 0, code: KnownCertificateListStatus.Draft, display: "" } })
            .then(e => feedback.showSuccess('Certificate updated'))
            .then(() => window.location.reload())
            .catch(e => feedback.showError(e.message ? `Cannot update certificate. Additional message: ${e.message}` : "Cannot update certificate"))}
        }
    function doSubmitForApproval(row: TDataModel, helper:EditorHelper<TDataModel>) { 
        if(!helper.isValid) {
            feedback.showError("Cannot submit certificate. Fill all mandatory fields")
        } else {
            doUpdate({ ...row, status: { id: 0, code: KnownCertificateListStatus.WaitingApproval, display: "" } })
            .then(e => feedback.showSuccess('Certificate submitted'))
            .catch(e => feedback.showError(e.message ? `Cannot submit certificate. Additional message: ${e.message}` : "Cannot submit certificate"))}
        }
    function doRePublish(row: TDataModel, helper:EditorHelper<TDataModel>) { 
        if(!helper.isValid) {
            feedback.showError("Cannot publish certificate. Fill all mandatory fields")
        } else {
            doUpdate({ ...row, status: { id: 0, code: KnownCertificateListStatus.Published, display: "" } })
            .then(e => feedback.showSuccess('Certificate published'))
            .then(() => window.location.reload())
            .catch(e => feedback.showError(e.message ? `Cannot publish certificate. Additional message: ${e.message}` : "Cannot publish certificate"))}
        }
    function doSave(row: TDataModel, helper:EditorHelper<TDataModel>) { 
        if(!helper.isValid) {
            feedback.showError("Cannot save certificate. Fill all mandatory fields")
        } else {
        doUpdate({ ...row, status: { id: 0, code: row.status.code, display: "" } })
        .then(e => feedback.showSuccess('Certificate updated'))        
        .then(() => window.location.reload())
        .catch(e => feedback.showError(e.message ? `Cannot update certificate. Additional message: ${e.message}` : "Cannot update certificate"))}
        }

    ///
    ///
    ///
    const onCommit = doUpdate

    ///
    /// Effectss
    React.useEffect(loadDataBase, []);

    ///
    /// Render
    function renderActions(helper: EditorHelper<TDataModel>) {

        // eslint-disable-next-line

        const onRemoveDraft = () => helper.isValid && doRemoveDraft(state!.item)
        const onSaveAsDraft = () => doSaveAsDraft(helper.values, helper)
        const onSubmitForApproval = () => doSubmitForApproval(helper.values, helper)
        const onRePublish = () => doRePublish(helper.values, helper)
        const onSave = () => doSave(helper.values, helper)

        return (<>
            {(helper.values.status.code !== KnownCertificateListStatus.Temporary &&
                helper.values.status.code !== KnownCertificateListStatus.Removed &&
                helper.values.status.code !== KnownCertificateListStatus.Imported &&
                helper.values.status.code !== KnownCertificateListStatus.Draft) ? <></> :
                <RoundButton variant="outlined" onClick={onSaveAsDraft}>
                    Save Draft
                </RoundButton>
            }
            {helper.values.status.code !== KnownCertificateListStatus.Draft ? <></> :
                <RoundButton variant="outlined" onClick={onRemoveDraft}>
                    Delete Draft
                </RoundButton>}
            {(helper.values.status.code !== KnownCertificateListStatus.Draft &&
                helper.values.status.code !== KnownCertificateListStatus.Imported) ? <></> :
                <RoundButton color="secondary" variant="contained" onClick={onSubmitForApproval}>
                    Submit for Approval
                </RoundButton>}
            {(helper.values.status.code !== KnownCertificateListStatus.Published &&
                helper.values.status.code !== KnownCertificateListStatus.Withdrawn) ? <></> :
                <RoundButton color="secondary" variant="contained" onClick={onRePublish}>
                    RePublish
                </RoundButton>}
            {(helper.values.status.code !== KnownCertificateListStatus.Published &&
                helper.values.status.code !== KnownCertificateListStatus.Withdrawn) ? <></> :
                <RoundButton color="secondary" variant="contained" onClick={onSave}>
                    Save
                </RoundButton>}
        </>)
    }

    const toEdit = state?.item

    return (
        <DetailsAppShell
            title={toEdit ? settings.pageSettings.title(toEdit) : ""}
            subtitle={toEdit ? settings.pageSettings.subtitle(toEdit) : ""}
            loading={!state}
            defaultGoBackPath=".."
            logout={pcsContext?.onLogout}
        >
        {!state ? <Loader /> : 
            <Formik
                initialValues={state.item}
                validationSchema={settings.validationSchema}
                onSubmit={values => onCommit(values)}
            >
                {formikProps => {
                    const helper = EditorBase.getHelper<TDataModel>(formikProps, () => { }, onCommit)                    
                    const renderActionsPart = () => renderActions(helper)
                    return (<Content
                        onBlur={formikProps.handleBlur}
                        loading={!state}
                        title={(itemIdParsed === 0) ? "Define new Certificate" : helper.values.title}
                        subtitle={`Release ${helper.values.release} | ${helper.values.status.display}`}
                        renderActions={renderActionsPart}>
                        <Editor helper={helper as EditorHelper<Partial<TDataModel>>} />
                    </Content>)
                }}
            </Formik>
        }
        </DetailsAppShell>
    )
}
function Editor(p: { helper: EditorHelper<Partial<TDataModel>> }) {
    const [tab, setTab] = useState(0);
    return (<TabsView

        tabIndex={tab}
        onChange={setTab}
        labels={[
            "Header Data",
            "Lifecycle",
            "Availability",
            "Prerequisites",
            "Requirements",
            "Alternatives",
            "Print Options",
            "Recommended",
            "Automatic Enrollment"]} >

        {tab === 0 ? <HeaderDataCardPart helper={p.helper} /> : <></>}
        {tab === 1 ? <LifecycleCardPart helper={p.helper} /> : <></>}
        {tab === 2 ? <AvailabilityCardPart helper={p.helper} /> : <></>}
        {tab === 3 ? <PrerequisitesCardPart /> : <></>}
        {tab === 4 ? <RequirementsFormPart  helper={p.helper}/> : <></>}
        {tab === 5 ? <AlternativesCardPart /> : <></>}
        {tab === 6 ? <PrintOptionsCardPart helper={p.helper}  /> : <></>}
        {tab === 7 ? <RecommendedCardPart /> : <></>}
        {tab === 8 ? <AutomaticEnrollmentCardPart /> : <></>}

    </TabsView>)
}