import { Typography, Card, FluidForEditors, DictionaryField, EditorHelper } from 'components';
import { API, CertificateListDefinitionDTO } from './model';

export type DTOModel = CertificateListDefinitionDTO;
export function AvailabilityCardPart(p: { helper: EditorHelper<Partial<DTOModel>>; }) {
    const data = p.helper.values
    
    return (
        <Card header='Availability'>
            <FluidForEditors>
                <DictionaryField name="forRegions" label="Region" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Region' })}/>
                <DictionaryField name="forCountries" label="Country/Territory" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Country', regions: data.forRegions })} />
            </FluidForEditors>
            <Typography variant="caption">
                You may select multiple values. <br/>
                Please note that by selecting “Region” value all related “Country” values are also selected by default.
                <br/>
                <br/>
            </Typography>
            <FluidForEditors>
                <DictionaryField name="forDivisions" label="Division" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Division' })} />
                <DictionaryField name="forChannels" label="Channel type" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Channel' })} />
                <DictionaryField name="forUserType" label="ABB / External" query={API.ICertificateListContract.dictionaryQuery({ key: 'UserType' })} />
            </FluidForEditors>
                <Typography variant="caption">If filters are not set up, everyone can enroll for the Certification</Typography>
        </Card>);
}
