import React, { forwardRef, useImperativeHandle, useRef, DispatchWithoutAction } from 'react';

import {
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import { Form, Formik, FormikTextField, Yup } from 'components/FormikField';

import {
    IconButton,
    Button,
    Grid,
    Typography,
    Card,
    TabsView,
    Stack,
    UsedIcons,
    Loader,
    EditorFormActions,
    ActionsRoot
} from 'components'

import { usePCSContext } from 'Router/PCSContext';

import * as Model from './model'

interface State {
    data?: Model.PrintingConfigurationDTO
    loadedOn: number
}

export function PrintingConfigurationCLIdentificatorsPart(p: { port: Model.ContractApi, division: DictionaryItemByCodeDTO; }) {
    const [state, setState] = React.useState<State>({ loadedOn: 0 });

    function loadData() {
        if (!p.division) return;

        p.port
            .get({ division: p.division })
            .then(x => setState({ data: x, loadedOn: new Date().getTime() }));
    }

    function doSave() {
        const capabilityHeaders = capabilityHeadersRef.current!.getData()
        const groupHeaders = groupHeadersRef.current!.getData()
        const clIdentificators = clIdentificatorsRef.current!.getData()
        const toSend: Model.PrintingConfigurationDTO = {
            division: state.data?.division,
            capabilityHeaders,
            groupHeaders,
            clIdentificators,
        }

        p.port
            .update(toSend)
    }
    ///
    React.useEffect(loadData, [p.division.id]);
    
    const capabilityHeadersRef = useRef<OrderableListEditorRef>(null)
    const groupHeadersRef = useRef<OrderableListEditorRef>(null)
    const clIdentificatorsRef = useRef<OrderableListEditorRef>(null)
    ///
    if (!p.division)
        return <></>;

    if (!state.data)
        return <Loader />;

    return (
        <TabsView
            labels={["HEADERS", "SUBHEADERS", "IDENTIFIERS"]}
            animateHeight={true}>
            <OrderableListEditor label="Header" data={state.data.capabilityHeaders} ref={capabilityHeadersRef} onChange={doSave} />
            <OrderableListEditor label="Subheader" data={state.data.groupHeaders} ref={groupHeadersRef} onChange={doSave} />
            <OrderableListEditor label="Identifier" data={state.data.clIdentificators} ref={clIdentificatorsRef} onChange={doSave} />
        </TabsView>

    );
}

interface OrderableListEditorItem {
    id: number
    desc: string
    order: number
}

interface OrderableListEditorProps {
    data?: OrderableListEditorItem[]
    onChange: DispatchWithoutAction,
    label: string
}

interface OrderableListEditorRef {
    getData: () => OrderableListEditorItem[]
}

const formValidationSchema = Yup.object<Record<keyof OrderableListEditorItem, Yup.AnySchema>>().shape({
    desc: Yup.string().required()
});

const OrderableListEditor = forwardRef<OrderableListEditorRef, OrderableListEditorProps>((p, _ref) => {

    useImperativeHandle(_ref, () => (
        {
            getData: () => state
        })
    )

    const pcsContext = usePCSContext()!
    const [state, setState] = React.useState<OrderableListEditorItem[]>(JSON.parse(JSON.stringify(p.data)) as OrderableListEditorItem[])
    const [selected, setSelected] = React.useState<OrderableListEditorItem | null>(null)
    const addingNew = !!(selected && (!state.find(x => x === selected)))

    function onSelect(item: OrderableListEditorItem | null) {
        setSelected(item)
    }

    function doDelete(v: OrderableListEditorItem) {
        const array = state

        const index = array.findIndex(x => x.desc.toLocaleLowerCase() == v.desc.toLocaleLowerCase())
        if (index < 0) return
        array.splice(index, 1)

        const newArray = array.map((i, index) => ({ ...i, order: index + 1 }))
        setState(c => (newArray))
        p.onChange()
    }

    function onRemoveItem(item: OrderableListEditorItem) {
        pcsContext.showQuestion({
            onConfirmed: () => doDelete(item),
            confirmDescription: `The ${p.label} will be deleted from this Division`,
            confirmTitle: `Delete ${p.label}`,
            confirmButton: "Delete"
        })
    }

    function onUpdateOrAddNew(item: OrderableListEditorItem) {
        const v = item.desc

        if (!state.find(x => x === selected)) {
            if (!v || state.find(x => x.desc.toLocaleLowerCase() == v.toLocaleLowerCase())) {
                return
            }

            state.push({ id: 0, desc: v, order: state.length })
            const newArray = state.map((i, index) => ({ ...i, order: index + 1 }))
            setState(c => (newArray))
        }
        else {
            selected!.desc = v
        }
        setSelected(null)
        p.onChange()
    }

    function onStartAddingNew() {
        setSelected({ id: 0, order: 0, desc: "" })
    }

    function onCancelAdd() {
        setSelected(null)
    }

    function renderEditRow(item: OrderableListEditorItem) {
        return <Formik
            initialValues={item}
            validationSchema={formValidationSchema}
            onSubmit={onUpdateOrAddNew}
        >
            {(formikProps) =>
                <Form>
                    <FormikTextField name="desc" label="Title" required />
                    <EditorFormActions isNew={!formikProps.values.id} formik={formikProps} onCancelAdd={onCancelAdd} />
                </Form>
            }
        </Formik>
    }

    function renderListItem(item: OrderableListEditorItem) {
        const inEditMode = selected === item

        if (inEditMode) {
            return <ListItem><Stack>{renderEditRow(item)}</Stack></ListItem>
        }

        return <ListItem key={item.desc}>
            <ListItemText primary={item.desc} />
            <ActionsRoot>
                <IconButton onClick={() => onSelect(item)}>
                    <UsedIcons.Edit />
                </IconButton>
                <IconButton onClick={() => onRemoveItem(item)}>
                    <UsedIcons.Delete />
                </IconButton>
            </ActionsRoot>
        </ListItem>
    }

    ///
    return (
        <Stack >
            {addingNew ? <Card header={`Add new ${p.label}`} elevation={0}>{renderEditRow(selected!)}</Card> : <></>}
            <Card transparent>
                <Grid item xs={12} lg={12}  >
                    <div style={{ display: "flex", flexGrow: 1 }}>
                        <Typography variant="h6">
                            {`Available ${p.label}s`}
                        </Typography>
                        <span style={{ flexGrow: 1 }} />
                        {addingNew ? <></> :
                            <Button type="button" color="secondary" onClick={onStartAddingNew}>
                                {`Add ${p.label}`}
                            </Button>}
                    </div>
                </Grid>
            </Card>

            <Card>
                <List>
                    {state.map(renderListItem)}
                </List>
            </Card>
        </Stack>
    );
})
