import fileDownload from 'js-file-download';

import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { PaginatedList, PaginationDto } from 'model';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';
import { getDateNow } from 'utils';
import { OldEnrollmentDetailsResult, OldEnrollmentResult } from 'OldEnrollments/model/OldEnrollmentResult';
import { OldEnrollmentActionResponse } from 'OldEnrollments/model/OldEnrollmentActionResponse';
import { UserSearchFilters } from 'MyProfile/model/UserSearchFilters';

const domainPath = '/old-enrollments';

const search = async (
  pagination: PaginationDto,
  filters: Partial<CatalogSearchFilters>
): Promise<PaginatedList<OldEnrollmentResult>> => {
  try {
    const response = await apiClient.post(`${domainPath}/search`, {
      ...filters,
      ...pagination,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const searchDetails = async (
  certificateId: number,
  filters: Partial<UserSearchFilters>
): Promise<OldEnrollmentDetailsResult> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${certificateId}/search`, {
        ...filters
      }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const exportToExcel = async (
  filters: Partial<CatalogSearchFilters>
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/export-excel`,
      {
        ...filters,
      },
      {
        responseType: 'blob',
      }
    );
    fileDownload(response.data, `Old_enrollments_${getDateNow()}.xlsx`);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

const exportToExcelDetails = async (
  certificateId: number,
  filters: Partial<UserSearchFilters>
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${certificateId}/export-excel`,
      {
        ...filters,
      },
      {
        responseType: 'blob',
      }
    );
    fileDownload(response.data, `Old_enrollments_${getDateNow()}.xlsx`);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface OldEnrollmentActionDto {
  certificateIds: number[];  
  inProcessOnly: boolean;
}

export interface OldEnrollmentReplaceDto 
  extends OldEnrollmentActionDto {
    noNotifications: boolean;
    skipEnrollment: boolean;
    action: string;
}
const remove = async (actionDto: OldEnrollmentActionDto): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/remove`,
      actionDto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const replace = async (actionDto: OldEnrollmentReplaceDto): Promise<OldEnrollmentActionResponse> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/replace`,
      actionDto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};


export const oldEnrollmentsApi = {
  search,
  searchDetails,
  exportToExcel,
  exportToExcelDetails,
  remove,
  replace
};
