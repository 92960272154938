import React, { Dispatch, DispatchWithoutAction } from 'react';
import {
    Content,
    DataTable,
    DataTableColumn,
    TablePagination,
    IconButton,
    UsedIcons,
    Button,
    Loader,
    SearchResults,
    useConfirmationModal,
    Card,
    Stack
} from 'components'
import { useToasts } from 'components/ToastProvider';
import { PaginatedList, PaginationDto } from 'model';
import { useHistory } from 'react-router-dom';

export type { DataTableColumn }

interface State<TDataModel, TQueryModel extends Exclude<PaginationDto, undefined>> {
    data?: PaginatedList<TDataModel>
    query: TQueryModel
    loadedOn: number
    adding: boolean
}

interface ViewPorts<TDataModel> {
    doDelete: Dispatch<TDataModel>
    doEdit: Dispatch<TDataModel>
    doGoToDetails: Dispatch<{ item: TDataModel, newWindow?: boolean }>
    doRefreshList: DispatchWithoutAction
}

export interface RowContext<TDataModel> {
    item: TDataModel
    isExpanded: boolean
    ports: ViewPorts<TDataModel>
}

export interface PagePorts {
    showSuccess: (success: string) => void
    showError: (error: string) => void
    reloadData: () => void
}

type InRowActionType = 'EDIT' | 'DETAILS'
export function PageBase<TDataModel, TQueryModel extends NonNullable<PaginationDto>>(p: {
    defaultQuery: TQueryModel
    pageSettings: {
        title: string
        subtitle: string
        editTitle?: string
        addTitle?: string
        details?: {
            route: (item: TDataModel) => string
        }
        menu?: {
            component: (item: TDataModel) => JSX.Element
        }
        newItem?: {
            actionCaption: string
            route?: () => string
            inPageAsRowView?: boolean
        }
        rowActionComponent?: (p: RowContext<TDataModel>) => JSX.Element
        selectedActionComponent?: (p: { selectedRows: string[], pagePorts: PagePorts }) => JSX.Element
        inRowAction?: InRowActionType
        editorWithPrefetch?: boolean
        deleteItem?: {
            confirmTitle: string
            confirmDescription: string
            confirmButton: string
        }
    }

    renderHandlers?: {
        list?: (render: () => JSX.Element) => JSX.Element
    }

    getIdFor: ((item: TDataModel) => string) | ((item: TDataModel) => number)
    contract: ApiContract<TDataModel, TQueryModel>

    columns: DataTableColumn<TDataModel>[]
    getRowView: (row: TDataModel | null, cancelHandler: React.DispatchWithoutAction, updateHandler: React.Dispatch<TDataModel>) => JSX.Element
    getSearchFiltersForm: (onQuery: React.Dispatch<TQueryModel>) => JSX.Element

    SearchFiltersForm?: (p: { defaultQuery: TQueryModel, onQuery: React.Dispatch<TQueryModel> }) => JSX.Element
}) {
    const inRowAction: InRowActionType = p.pageSettings.inRowAction ?? 'EDIT'

    type MyState = State<TDataModel, TQueryModel>;
    const { defaultQuery, contract, pageSettings } = p;
    const { showSuccess, showError } = useToasts();
    const { Modal, openModal } = useConfirmationModal({ ...p.pageSettings.deleteItem, onConfirmed: doDelete })

    ///
    ///
    const [state, setState] = React.useState<MyState>({ query: defaultQuery, loadedOn: 0, adding: false });

    ///
    ///
    function loadData(query?: TQueryModel) {
        const queryToUse = query ?? defaultQuery;
        contract.find(queryToUse)
            .then(x => setState({ ...state, query: query ?? state.query, data: x, loadedOn: new Date().getTime(), adding: false }));
    }
    function loadDataBase() {
        setState(s => ({ ...s, data: undefined }))
        contract
            .find(state.query)
            .then(x => setState(s => ({ ...s, data: x, loadedOn: new Date().getTime(), adding: false })))
    }

    function doDelete(row: TDataModel) {
        const id = p.getIdFor(row)
        contract.delete(id)
            .then(e => showSuccess('Item deleted'))
            .then(() => loadDataBase())
            .catch(e => showError(e.message ? `Cannot delete item. Additional message: ${e.message}` : "Cannot delete item."))
    }

    function doUpdate(row: TDataModel) {
        contract
            .update(row)
            .then(e => showSuccess('Item updated'))
            .then(() => loadDataBase())
            .catch(e => showError(e.message ? `Cannot update item. Additional message: ${e.message}` : "Cannot update item."))
    }

    function doAdd(row: TDataModel) {
        contract
            .add(row)
            .then(e => showSuccess('Item added'))
            .then(() => loadDataBase())
            .catch(e => showError(e.message ? `Cannot add item. Additional message: ${e.message}` : "Cannot add item."))
    }

    function doGoToDetails(row: TDataModel, onNewWindow?: boolean) {
        const route = p.pageSettings.details!.route(row)
        if (onNewWindow)
            window.open(route, '_blank')
        else
            history.push(route)
    }

    ///
    ///
    ///

    ///
    /// Effectss
    React.useEffect(
        loadDataBase,
        [state.query]);

    ///
    ///
    const onQuery = (query: TQueryModel) =>
        setState(s => ({ ...s, query: { ...query } }))
    const onChangeSort = (sortExpression: keyof TDataModel, sortDescending: boolean) =>
        setState(s => ({ ...s, query: { ...s.query!, sortDescending, sortExpression } }));

    const onChangePage = (currentPageIndex: number) =>
        setState(s => ({ ...s, query: { ...s.query, currentPageIndex } }))

    const onChangeRowsPerPage = (pageSize: number) =>
        setState(s => ({ ...s, query: { ...s.query, pageSize } }))

    const onEditRow = (row: TDataModel) => { };


    const onGoToDetails = doGoToDetails;

    const onUpdateRow = (item: TDataModel) => {
        const itemId = p.getIdFor(item);
        const isNew = state.data!.result.findIndex(x => p.getIdFor(x) === itemId) < 0

        isNew ? doAdd(item) : doUpdate(item);
    };

    const onDeleteRow = (item: TDataModel) => openModal(item)

    const onBeginAdd = () => setState({ ...state, adding: true });
    const onCancelAdd = () => setState({ ...state, adding: false });

    ///
    ///
    const getColumns = () => p.columns;

    const history = useHistory();
    function getRowActions(row: TDataModel, collapseRow: DispatchWithoutAction, isExpanded: boolean) {


        if (p.pageSettings.rowActionComponent) {
            const ctx = {
                item: row,
                isExpanded,
                ports: {
                    doDelete: onDeleteRow,
                    doEdit: (row: TDataModel) => {
                        onEditRow(row);
                        collapseRow();
                    },
                    doGoToDetails: (p: { item: TDataModel, newWindow?: boolean }) => {
                        onGoToDetails(p.item, p.newWindow)
                    },
                    doRefreshList: () => {
                        showSuccess("Item withdrawn");
                        loadDataBase();
                    }
                }
            }
            const result = p.pageSettings.rowActionComponent(ctx)
            return result
        }

        const editDetails = <IconButton
            onClick={() => {
                onEditRow(row);
                collapseRow();
            }}
        >
            {inRowAction == "EDIT" ? <UsedIcons.Edit /> : <UsedIcons.List />}
        </IconButton>

        const deletePart = <IconButton
            onClick={() => onDeleteRow(row)}
        >
            <UsedIcons.Delete />
        </IconButton>

        const gotToDetails = p.pageSettings.details === undefined ? <></> :
            <IconButton onClick={e => onGoToDetails(row, e.ctrlKey)} >
                <UsedIcons.NavigateNext />
            </IconButton>

        const closePart = <IconButton
            onClick={() => {
                collapseRow();
            }}
        >
            <UsedIcons.Close />
        </IconButton>

        return !isExpanded ?
            (<>
                {editDetails}
                {gotToDetails}
                {deletePart}
            </>) :
            (<>
                {gotToDetails}
                {closePart}
            </>);
    }
    function getRowView(row: TDataModel | null, cancelHandler: React.DispatchWithoutAction) {
        const isNew = !row
        const editAdd = isNew ? (p.pageSettings.addTitle ?? "Add") : (p.pageSettings.editTitle ?? "Edit")
        return (
            <Card elevation={isNew ? 1 : 0} header={inRowAction == "DETAILS" ? "" : editAdd} >
                {getRowViewBase(row, cancelHandler)}
            </Card>)
    }

    function getRowViewBase(row: TDataModel | null, cancelHandler: React.DispatchWithoutAction) {
        if (!row) {
            if (!p.pageSettings.newItem) return (<></>)
            if (p.pageSettings.newItem.inPageAsRowView) return p.getRowView(row, cancelHandler, onUpdateRow)
            if (!p.pageSettings.newItem.route) return (<></>)

            history.push(p.pageSettings.newItem.route())
            return (<></>)
        }

        if (p.pageSettings.editorWithPrefetch)
            return <EditorWithPrefetch
                itemId={p.getIdFor(row)}
                getRowView={p.getRowView}
                contract={p.contract}
                onCancel={cancelHandler}
                onCommit={onUpdateRow}
            />
        return p.getRowView(row, cancelHandler, onUpdateRow);
    }

    function getNewRowView() {
        return !state.adding ?
            <></> :
            <>{getRowView(null, onCancelAdd)}</>
    }

    function getSearchFiltersForm() {
        return p.getSearchFiltersForm(onQuery);
    }


    function getSearchResultActiona() {
        return state.adding ? (<></>) : (<>
            <Button type="button" color="secondary" onClick={onBeginAdd}>
                {p.pageSettings.newItem?.actionCaption ?? "ADD"}
            </Button>
        </>);
    }

    ///
    /// Effects
    // React.useEffect(
    //     loadData,
    //     []); 

    ///
    /// Render

    const renderList = () => (
        <>
            {getNewRowView()}
            <SearchResults total={state.data?.total ?? 0} loading={!state.data} actions={getSearchResultActiona()} />

            {!state.data ?
                <><Loader /></> :
                <><DataTable
                    key={state.loadedOn}

                    enableSort={("sortExpression" in state.query)}

                    defaultSort={(("sortExpression" in state.query)) ? {
                        //@ts-ignore eslint-disable-next-line
                        sortExpression: state.query.sortExpression,
                        //@ts-ignore eslint-disable-next-line
                        sortDescending: !state.query.sortDescending
                    } : undefined}
                    onSortRequest={(direction, sortExpression) =>
                        onChangeSort(
                            sortExpression,
                            direction !== 'desc'
                        )
                    }

                    columns={getColumns()}
                    renderRowActions={getRowActions}
                    rows={state.data?.result ?? []}
                    selectIsRowSelectable={x => true}
                    checkboxSelection={!!p.pageSettings.selectedActionComponent}
                    renderSelectedActions={(x, y) => p.pageSettings.selectedActionComponent && p.pageSettings.selectedActionComponent({ selectedRows: x, pagePorts: { showSuccess, showError, reloadData: loadDataBase } })}
                    loading={!state.data}
                    rowSelector={p.getIdFor}
                    hideCollapseColumn
                    renderCollapseRow={(row, collapse) => getRowView(row, collapse)} />
                    <TablePagination
                        onChangePage={(e, p) => onChangePage(p)}
                        onChangeRowsPerPage={(e) => onChangeRowsPerPage(parseInt(e.target.value, 10))}

                        pagination={state.data ?? { currentPageIndex: 0, pageSize: 10, total: 0 }} />
                </>
            }
            <Modal />
        </>)

    return (
        <Content
            title={pageSettings.title}
            subtitle={pageSettings.subtitle}
        >
            <Stack>
                {getSearchFiltersForm()}

                {p.renderHandlers && p.renderHandlers.list ? p.renderHandlers.list(renderList) : renderList()}
            </Stack>
        </Content>);
}


function EditorWithPrefetch<TDataModel, TQueryModel extends PaginationDto>(p: {
    itemId: string | number
    onCancel: React.DispatchWithoutAction
    onCommit: React.Dispatch<TDataModel>
    contract: ApiContract<TDataModel, TQueryModel>
    getRowView: (row: TDataModel | null, cancelHandler: React.DispatchWithoutAction, updateHandler: React.Dispatch<TDataModel>) => JSX.Element
}) {
    const [state, setState] = React.useState<{ fetched?: TDataModel }>({});

    function loadDataBase() {
        p.contract.get(p.itemId)
            .then(x => setState({ fetched: x }));
    }
    React.useEffect(
        loadDataBase,
        []);

    const toEdit = state.fetched
    if (!toEdit) return <Loader />
    return (p.getRowView(toEdit, p.onCancel, p.onCommit))
}