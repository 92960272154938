import { RoleType } from 'MyProfile/enum/RoleType';
import { NEW_ADMIN } from 'featureToggles';
import { generatePath } from 'react-router-dom';
import { RouteMeta, RouteMetaBase } from './@types';

export const notFoundRoute = '/not-found';

export const myProfileRoute: RouteMetaBase = {
  path: '/my-profile',
  role: [],
};

export const development: RouteMetaBase = {
  path: '/development',
  role: [],
};

export const myCertificationsRoute: RouteMetaBase = {
  path: '/',
  role: [],
};

export const catalogRoute: RouteMetaBase = {
  path: '/catalog',
  role: [],
};

export const printOutMyCertificatesRoute: RouteMetaBase = {
  path: '/print-out-certificates',
  role: [],
};

export interface CatalogDetailsParams {
  certificationId: string;
}

export const catalogDetailsPath = '/catalog/:certificationId/details';

// eslint-disable-next-line max-len
export const catalogDetailsRoute: RouteMeta<CatalogDetailsParams> = {
  path: catalogDetailsPath,
  role: [...catalogRoute.role],
  createRoute: (params: CatalogDetailsParams) =>
    generatePath(catalogDetailsPath, params),
};

export const helpAndSupportRoute: RouteMetaBase = {
  path: '/help-and-support',
  role: [],
};

export const pcsReportingRoute: RouteMetaBase = {
  path: '/pcs-reporting',
  role: [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.Reader,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
  ],
};
export const certificationsStatusRoute: RouteMetaBase = {
  path: '/certifications-status',
  role: [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.Reader,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
  ],
};
export const certificationsByPersonRoute: RouteMetaBase = {
  path: '/certifications-by-person',
  role: [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.Reader,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
  ],
};

export interface CertificationPlansDetailsParams {
  certificationPlanId: string;
}

export const certificationPlansDetailsPath =
  '/certification-plans/:certificationPlanId/details';

// eslint-disable-next-line max-len
export const certificationPlansDetailsRoute: RouteMeta<CertificationPlansDetailsParams> = {
  path: certificationPlansDetailsPath,
  role: [...certificationsByPersonRoute.role],
  createRoute: (params: CertificationPlansDetailsParams) =>
    generatePath(certificationPlansDetailsPath, params),
};

export const certificationPlansEnrollRequestDetailsPath =
  '/certification-plans/:certificationPlanId/enroll-request-details';

// eslint-disable-next-line max-len
export const certificationPlansEnrollRequestDetailsRoute: RouteMeta<CertificationPlansDetailsParams> = {
  path: certificationPlansEnrollRequestDetailsPath,
  role: [...myCertificationsRoute.role],
  createRoute: (params: CertificationPlansDetailsParams) =>
    generatePath(certificationPlansEnrollRequestDetailsPath, params),
};

export interface CertificationsByPersonDetailsParams {
  userId: string;
}

export const certificationsByPersonDetailsPath =
  '/certifications-by-person/:userId/details';

// eslint-disable-next-line max-len
export const certificationsByPersonDetailsRoute: RouteMeta<CertificationsByPersonDetailsParams> = {
  path: certificationsByPersonDetailsPath,
  role: [...certificationsByPersonRoute.role],
  createRoute: (params: CertificationsByPersonDetailsParams) =>
    generatePath(certificationsByPersonDetailsPath, params),
};

export const certificationsByPersonMyLearningPath =
  '/certifications-by-person/:userId/details/my-learning';

// eslint-disable-next-line max-len
export const certificationsByPersonMyLearningRoute: RouteMeta<CertificationsByPersonDetailsParams> = {
  path: certificationsByPersonMyLearningPath,
  role: [...certificationsByPersonRoute.role],
  createRoute: (params: CertificationsByPersonDetailsParams) =>
    generatePath(certificationsByPersonMyLearningPath, params),
};

export const certificationsByPersonPrintOutCertificatesPath =
  '/certifications-by-person/:userId/details/print-out-certificates';

// eslint-disable-next-line max-len
export const certificationsByPersonPrintOutCertificatesRoute: RouteMeta<CertificationsByPersonDetailsParams> = {
  path: certificationsByPersonPrintOutCertificatesPath,
  role: [...certificationsByPersonRoute.role],
  createRoute: (params: CertificationsByPersonDetailsParams) =>
    generatePath(certificationsByPersonPrintOutCertificatesPath, params),
};

export const uncompletedRequirementsRoute: RouteMetaBase = {
  path: '/uncompleted-requirements',
  role: [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.Reader,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
  ],
};
export const pendingApprovalsRoute: RouteMetaBase = {
  path: '/pending-approvals',
  role: [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
    RoleType.Approver,
  ],
};
export const massEnrollmentRoute: RouteMetaBase = {
  path: '/mass-enrollment',
  role: [
    RoleType.Administrator,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
  ],
};
export const bulkUploadRoute: RouteMetaBase = {
  path: '/bulk-upload',
  role: [
    RoleType.Administrator,
    RoleType.Supervisor,
    RoleType.HierarchicalManager,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.PartnerSupervisor,
  ],
};
export const internalUsersRoute: RouteMetaBase = {
  path: '/internal-users',
  role: [
    RoleType.Administrator,
    RoleType.HierarchicalManager,
    RoleType.Supervisor,
    RoleType.ChannelPartnerManager,
  ],
};
export const addInternalUsersRoute: RouteMetaBase = {
  path: `${internalUsersRoute.path}/add-users`,
  role: [
    RoleType.Administrator,
    RoleType.HierarchicalManager,
    RoleType.Supervisor,
    RoleType.ChannelPartnerManager,
  ],
};
export const externalUsersRoute: RouteMetaBase = {
  path: '/external-users',
  role: [
    RoleType.Administrator,
    RoleType.ABBTrainingManager,
    RoleType.ChannelPartnerManager,
    RoleType.Supervisor,
    RoleType.PartnerSupervisor,
  ],
};
export const addExternalUsersRoute: RouteMetaBase = {
  path: `${externalUsersRoute.path}/add-users`,
  role: externalUsersRoute.role,
};
export const approvalGroupsRoute: RouteMetaBase = {
  path: '/approval-groups',
  role: [RoleType.Administrator],
};

export interface ApprovalGroupsDetailsParams {
  groupId: string;
}

export const approvalGroupsDetailsPath = '/approval-groups/:groupId/details';

// eslint-disable-next-line max-len
export const approvalGroupsDetailsRoute: RouteMeta<ApprovalGroupsDetailsParams> = {
  path: approvalGroupsDetailsPath,
  role: [RoleType.Administrator],
  createRoute: (params: ApprovalGroupsDetailsParams) =>
    generatePath(approvalGroupsDetailsPath, params),
};

export const archivedUsersRoute: RouteMetaBase = {
  path: '/archived-users',
  role: [RoleType.Administrator],
};

export const manageCertificatesRoute: RouteMetaBase = {
  path: '/manage-certificates',
  role: [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.CertificateBuilder,
  ],
};

const manageCertificatesDetailsRoutePath = '/manage-certificates/:itemId/details'
export const manageCertificatesDetailsRoute: RouteMeta<{ itemId: string }> = {
  path: manageCertificatesDetailsRoutePath,
  role: !NEW_ADMIN ? [RoleType.Developer] : [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.CertificateBuilder
  ],

  createRoute: (params: { itemId: string }) =>
    generatePath(manageCertificatesDetailsRoutePath, params)
};


export const manageCompaniesRoute: RouteMetaBase = {
  path: '/manage-companies',
  role: [RoleType.Administrator],
};
export const scopeKeywordsRoute: RouteMetaBase = {
  path: '/scope-keywords',
  role: [RoleType.Administrator],
};
export const digitalSignatureRoute: RouteMetaBase = {
  path: '/digitalSignature',
  role: [RoleType.Administrator],
};
export const printingStandardRoute: RouteMetaBase = {
  path: '/printing-standard',
  role: [RoleType.Administrator],
};
export const printingHeadersRoute: RouteMetaBase = {
  path: '/printing-headers',
  role: [RoleType.Administrator],
};

export const trainingSetsRoute: RouteMetaBase = {
  path: '/training-sets',
  role: [
    RoleType.Administrator,
    RoleType.Supervisor
  ],
};

const trainingSetDetailsRoutePath = `${trainingSetsRoute.path}/:itemId/details`
export const managTrainingSetDetailsRoute: RouteMeta<{
  itemId: number;
}> = {
  path: trainingSetDetailsRoutePath,
  role: !NEW_ADMIN ? [RoleType.Developer] : [
    RoleType.Administrator,
    RoleType.CertificationAuthority,
    RoleType.CertificateBuilder
  ],
  // eslint-disable-next-line
  createRoute: (params: any) => {
    return generatePath(trainingSetDetailsRoutePath, params)
  },
};


export const printingCertificatesRoute: RouteMetaBase = {
  path: '/printing-certificates',
  role: [RoleType.Administrator],
};
export const oldEnrollmentsRoute: RouteMetaBase = {
  path: '/old-enrollments',
  role: [RoleType.Administrator],
};

export interface OldEnrollmentsDetailsParams {
  certificateId: string;
}

const oldEnrollmentsDetailsRoutePath = '/old-enrollments/:certificateId/details'
export const oldEnrollmentsDetailsRoute: RouteMeta<{ certificateId: string }> = {
  path: oldEnrollmentsDetailsRoutePath,
  role: [RoleType.Administrator],

  createRoute: (params: OldEnrollmentsDetailsParams) =>
    generatePath(oldEnrollmentsDetailsRoutePath, params)
};
