import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';
import { useParams } from 'react-router-dom';
import { useMutation } from "react-query";
import { oldEnrollmentsApi } from "OldEnrollments/service/oldEnrollments.api";
import { ByPersonFiltersForm } from 'ByPerson/component';
import {
  ByPersonSearchFilters,
  defaultSearchFilters,
} from 'ByPerson/model/ByPersonSearchFilters';
import { OldEnrollmentsDetailsTable } from 'OldEnrollments/component/OldEnrollmentsDetailsTable';
import { Loader } from 'components/Loader/Loader';
import { useToasts, Button } from "components";
import {
  useCertificateShortDetailsQuery,
} from 'CertificationList/query/certificationListQuery';
import { Content } from 'components/Page';
import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { OldEnrollmentsDetailsParams, oldEnrollmentsRoute } from 'route';
import { useOldEnrollmentsDetailsQuery } from 'OldEnrollments/query/oldEnrollmentsQuery';


export interface OldEnrollmentsDetailsPageProps extends AppShellBaseProps {}

export const OldEnrollmentsDetailsPage: FC<OldEnrollmentsDetailsPageProps> = ({ logout }) => {
  const { certificateId } = useParams<OldEnrollmentsDetailsParams>();
  const { isLoading, data } = useCertificateShortDetailsQuery(
    Number(certificateId)
  );
  const { showSuccess, showError } = useToasts(); 
  const [filters, setFilters] = useState<ByPersonSearchFilters>(
    defaultSearchFilters
  );
  const {
    mutate: exportToExcel,
    isLoading: isExportToExcelLoading,
  } = useMutation(() => oldEnrollmentsApi.exportToExcelDetails(Number(certificateId), filters), {
    onSuccess: () => showSuccess('Export to excel success'),
    onError: () => showError('Export to excel failed. Too many records on search. Must be less than 10000.'),
  });
  const {
    isLoading: enrollmentsLoading,
    data: enrollmentsData,
    refetch: enrollmentsRefetch
  } = useOldEnrollmentsDetailsQuery(Number(certificateId), filters);
  
  return (
    <DetailsAppShell
      title={data?.title ?? ''}
      subtitle={`${data?.release} - ${data?.status}`}
      loading={isLoading}
      logout={logout}
      defaultGoBackPath={oldEnrollmentsRoute.path}
    >
      <Content
        loading={isLoading}
        title="Users with enrollments"
        subtitle="This view is intended to replace enrolled certifications with the newest available version."        
      >
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <ByPersonFiltersForm
            onFiltersSubmit={(searchFilters) => {
              setFilters(searchFilters);
            }}
            renderPageActions={() =>
              <Button
                onClick={() => exportToExcel()}
                disabled={isExportToExcelLoading}
              >
                {isExportToExcelLoading ? (
                  <Loader size={24} />
                ) : (
                  <>
                    <ExportIcon /> &nbsp;Export to excel
                  </>
                )}
              </Button>
          }
          />
        </Grid>
        <Grid item xs={12}>
        <OldEnrollmentsDetailsTable
              displayRowActions 
              onActionSuccess={enrollmentsRefetch}             
              DataTableProps={{
                rows: enrollmentsData?.users ?? [],
                loading: enrollmentsLoading,
                noDataMessage: 'No requirements to display',
                checkboxSelection: true,
              }}
            />        
        </Grid>
      </Grid>
      </Content>
    </DetailsAppShell>
  );
};