import React from 'react'
import { dictionaryApi } from 'Dictionary/service/dictionary.api';
import * as Model from 'Dictionary/model/ScopeDetail'

import {
    Grid,
    EditorFormActions
} from 'components'
import { FormikTextField } from 'components/FormikField/FormikTextField/FormikTextField';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { BusinessUnitSelectField, ScopeSelectField } from 'Dictionary/component';

type DTOModel = Model.ScopeKeyword

export default function Editor(p: {
    item: DTOModel
    onCancel: React.DispatchWithoutAction
    onCommit: React.Dispatch<DTOModel>
}) {
    let similarNameError: boolean = false;
    const x = p.item
    const toEdit = {
        ...x,
        division: x.division ? { ...x.division, name: x.division?.description } : undefined,
        scope: x.scope ? { ...x.scope, name: x.scope?.description } : undefined
    } as DTOModel

    const onCancelAdd = () => p.onCancel()
    const onUpdateRow = (item: DTOModel) => p.onCommit(item)

    function renderForm(formikProps: FormikProps<DTOModel>) {
        return (
            <Form>
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <FormikTextField name="title" label="Title" required  helperText={similarNameError ? (<span style={{color:'red', fontWeight: 'bold', fontSize:'small'}}>A similar Scope already exists</span>) : null} error={similarNameError}/>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <ScopeSelectField name="scope" multiple={false} disabled={!!toEdit.id} forNewItem={!toEdit.id} />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <BusinessUnitSelectField name="division" multiple={false} disabled={!!toEdit.id} />
                        </Grid>
                    </Grid>
                </>
                <>
                    <EditorFormActions isNew={!formikProps.values.id} formik={formikProps} onCancelAdd={onCancelAdd} />
                </>
            </Form>
        )
    }

    const formValidationSchema = Yup.object().shape({
        scope: Yup.object().required("This field is required").nullable(),
        division: Yup.object().required("This field is required").nullable(),
        title: Yup.string().required().required()
        .when(["scope", "division"], {
            is: (scope: DictionaryItemByCodeDTO, division: DictionaryItemByCodeDTO) => !!scope && !!division,
            then: Yup.string().required().test("checkIsNameUnique","A similar Scope already exists",
            (value, context) => 
              new Promise((resolve) => {
                const query = {
                    division: context.parent.division,
                    scope: context.parent.scope
                };
                dictionaryApi.scopeKeyword.find(query as Model.ScopeDetailFindQuery).then(res => {
                    similarNameError = res.result.find(sc => sc.title == value) != null;
                    resolve(true);
                });
              })
           ),
        }),    
    });

    if (!toEdit) return (<></>)
    return (<>
        <Formik
            initialValues={toEdit}
            validationSchema={formValidationSchema}
            onSubmit={onUpdateRow}
        >
            {(formikProps) => renderForm(formikProps)}
        </Formik>
    </>)
}
