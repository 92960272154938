import { FC } from 'react';
import { oldEnrollmentsDetailsRoute } from 'route';
import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { Card } from 'components/Page';
import { OldEnrollmentResult } from 'OldEnrollments/model/OldEnrollmentResult';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const resultColumns: DataTableColumn<OldEnrollmentResult>[] = [
  {
    field: 'certificateTitle',
    headerName: 'Certificate',    
  },
  {
    field: 'certificateRelease',
    headerName: 'Release',    
  },
  {
    field: 'certificateDivision',
    headerName: 'Division',    
  },
  {
    field: 'enrollmentsCount',
    headerName: 'Enrolled users',    
  },
];

export interface OldEnrollmentsTableProps
  extends Omit<DataTableProps<OldEnrollmentResult>, 'columns'> {}

export const OldEnrollmentsTable: FC<OldEnrollmentsTableProps> = (props) => {

  const handleOpenDetails = (certificateId: number) => () =>
    window.open(
      `${window.location.origin}${oldEnrollmentsDetailsRoute.createRoute({
        certificateId: String(certificateId),
      })}`,
      '_blank'
    );
  
  return (
  <DataTable    
    {...props}
    columns={resultColumns}    
    rowSelector={(row) => row.certificateId}
    noDataMessage="No results"
    renderCollapseRow={(row) => (
      <Card>
      <DataTable    
        columns={resultColumns}
        rows={row?.previousReleases ?? []}
        rowSelector={(r) => r.certificateId}        
        renderRowActions={(r) => (
            <IconButton onClick={handleOpenDetails(r.certificateId)}>
              <NavigateNextIcon />
            </IconButton>
        )}
      />
      </Card>
    )}
  />
);

};
