import {
    IconButton,
    Button, Typography, Card, Stack,
    DataTable, UsedIcons, FluidForEditors,
    ActionsRoot
} from 'components';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Yup, Formik, FormikTextField, DictionaryFieldByCode, FieldArray, EditorHelper } from 'components/FormikField';
import { useState } from 'react';
import { usePCSContext } from 'Router/PCSContext';

import * as Model from './model';
import { KnownCertificateListStatus } from './model';

type TRootModel = Model.CertificateListDefinitionDTO
type TItemModel = Model.CertificateListRequirementDTO

export function RequirementsFormPart(p: { helper: EditorHelper<Partial<TRootModel>>; }) {
    const [show, setShow] = useState(false);
    const togleShow = () => setShow(prev => !prev)
    const editable =  p.helper.values.status?.code === KnownCertificateListStatus.Temporary || p.helper.values.status?.code === KnownCertificateListStatus.Draft;

    return (
        <Stack direction='column'>

            <Card header='Requirements'>
                <RequirementsList hideDelete={!editable} />
            </Card>
            {editable ? <>{!show ?
                <ActionsRoot>
                    <Button color="secondary" onClick={togleShow}>
                        Add Requirement
                    </Button>
                </ActionsRoot>
                :
                <Card header='Add new requirement'>
                    <RequirementEditor onEnd={togleShow} />
                </Card>}</>
                : null}
        </Stack>);
}

function RequirementsList(p: {hideDelete: boolean}) {
    const pcsContext = usePCSContext()!;

    return <FieldArray name='requirements'>
        {arrayHelpers => {
            const items = arrayHelpers.form.values[arrayHelpers.name] as TItemModel[];            
            function doDelete(item: TItemModel) {
                arrayHelpers.remove(items.indexOf(item));
            }

            function onDelete(item: TItemModel) {
                pcsContext.showQuestion({
                    onConfirmed: () => doDelete(item),
                    confirmTitle: "Do you want to delete requirement?",
                    confirmDescription: "Deleting requirement removes all alternatives including it",
                    confirmButton: "DELETE"
                });
            }
            return (
                <DataTable
                    rows={items.sort((a, b) => (a.code).localeCompare(b.code))}
                    rowSelector={x => items.indexOf(x)}
                    columns={[
                        { field: "code", headerName: "Code", width: 60 },
                        {
                            field: "description", headerName: "Description", width: 400, renderField: (r) => (<>
                                {r.description}<br />
                                <Typography variant="caption" style={{ fontStyle: 'italic', marginTop: 8 }}>
                                    {r.extendedDescription}
                                </Typography></>)
                        },
                        { field: 'approverType', headerName: 'Approver', renderField: (r) => r.approverType.display },
                        { field: 'expiration', headerName: 'Expiration', renderField: (r) => r.canExpire.code === "YES" ? `${r.expiration} month(s)` : 'Never' },
                        { field: 'renewal', headerName: 'Renewal', renderField: (r) => r.canRenew.code === "YES" ? `${r.renewal} month(s)` : 'Never' },
                    ]}
                    noDataMessage="Currently there are no requirements set up. Click “Add requirement” button to add one."
                    renderCollapseRow={(item, collapseRow) => (
                        <Card>
                            <RequirementEditor
                                item={item}
                                onEnd={collapseRow}
                            />
                        </Card>)}

                    renderRowActions={(item, collapseRow) => (
                        <>
                            <IconButton onClick={collapseRow}>
                                <UsedIcons.Edit />
                            </IconButton>
                            { !p.hideDelete && (
                            <IconButton onClick={() => onDelete(item)}>
                                <UsedIcons.Delete />
                            </IconButton>
                            )}
                        </>)}
                    hideCollapseColumn />)
        }}
    </FieldArray>
}

function RequirementEditor(p: {
    item?: TItemModel | undefined;
    onEnd: () => void;
}) {
    const fieldName: keyof TRootModel = "requirements";
    const data: Partial<TItemModel> = p.item ?? {
        id: 0,
        renewal: 0,
        expiration: 0,
        canExpire: { id: 0, code: "NO", display: "No"},
        canRenew: { id: 0, code: "NO", display: "No"},
        approverType: { id: 7, code: "7", display: "Automatically Approved when Submitted" },
        externalLink: "",
        externalLinkDescription: "",
        isAttachmentMandatory: false
    };

   const [showLinks, setShowLinks] = useState(!!data.externalLink);

   return <FieldArray name={fieldName}>
        {arrayHelpers => {
            const { form } = arrayHelpers
            const otherCodes = (arrayHelpers.form.values[fieldName] as Partial<TItemModel>[]).filter(r => r.code !== data.code).map(r => r.code);            
            const isNew = data.id === 0
            const cancel = p.onEnd
            const submit = (value: Partial<TItemModel>) => {
                const valuesToSubmit = value;
                if(!showLinks){
                    valuesToSubmit.externalLink = "";
                    valuesToSubmit.externalLinkDescription = "";
                }
                const items = arrayHelpers.form.values[fieldName] as Partial<TItemModel>[];                
                const currentIndex = items.indexOf(data);
                currentIndex == -1 ? arrayHelpers.push(valuesToSubmit) : arrayHelpers.replace(currentIndex, valuesToSubmit);
                p.onEnd();            
            }

            const makeValidationSchema = (linkValidation: boolean, codes: (string | undefined)[]) => 
                Yup.object().shape({
                    code: Yup.string().label("Code").matches(/^\d+$/, "Please provide correct code - from 1 up to 3 digits").min(1).max(3, "Code must be at most 3 digits")
                        .required()
                        .test("checkIsCodeUnique","Code already exists",(value) => !codes.includes(value))
                        ,
                    description: Yup.string().label("Title").required(),
                    extendedDescription: Yup.string().label("Description").required(),
                    approverType: Yup.object().label("Approver").required(),
                    approverGroup: Yup.object().nullable().when(
                        'approverType', {
                        is: (approverType: DictionaryItemByCodeDTO | undefined) => (approverType?.code === "1"),
                        then: Yup.object().required()
                    }),
                    canExpire: Yup.object().nullable(),
                    expiration: Yup.number().when(
                        'canExpire', {
                        is: (x: DictionaryItemByCodeDTO | undefined) => (x?.code === "YES"),
                        then: () => Yup.number().min(1, "Expiration must be greater than 0"),                        
                    }),
                    renewal: Yup.number().when(
                        'canRenew', {
                        is: (x: DictionaryItemByCodeDTO | undefined) => (x?.code === "YES"),
                        then: () => Yup.number().min(1, "Renewal must be greater than 0"),                        
                    }),
                    externalLink: Yup.string().when([], {
                        is: () => linkValidation,
                        then: Yup.string().required().url(),                    
                    }),
                    externalLinkDescription: Yup.string().when([], {
                        is: () => linkValidation,
                        then: Yup.string().required(),                    
                    })
                }
            )
            
            return (
                <Formik
                    initialValues={data}
                    validationSchema={makeValidationSchema(showLinks, otherCodes)}
                    onSubmit={submit}
                >
                    {formikProps => (<>
                        <Tooltip title="Note that the Requirements code IDs must remain the same if these are the same requirements as in old version (the new added Requirements should have a new code). It will enable Requirements status transfer during Old Enrollements Management." >
                                <UsedIcons.HelperIcon />
                        </Tooltip>  
                        <Grid container spacing={2}>
                                
                            <Grid item xs={12} lg={1}>
                                
                                <FormikTextField required name="code" label="Code" inputProps={{ min: 1, maxLength: 3 }} />
                                
                                
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <FormikTextField required name="description" label="Title"  />
                            </Grid>
                        </Grid>
                        <FormikTextField name="extendedDescription" label="Description" multiline rows={4} inputProps={{ style: { paddingTop: 10 }}} />
                        <FluidForEditors inRow={4}>
                            <DictionaryFieldByCode required name="canExpire" label="Expiration" query={{ key: 'RequirementYesNo' }} />
                            <FormikTextField name="expiration" label="Expiration  period (months)" type="number" helperText='month(s)' 
                                disabled={formikProps.values.canExpire?.code === "NO"} inputProps={{ min: 0, onClick: (e) => e.currentTarget.select()}} />
                            <DictionaryFieldByCode required name="canRenew" label="Renewal" query={{ key: 'RequirementYesNo' }} />
                            <FormikTextField name="renewal" label="Renewal (Months)" type="number" helperText='month(s) before expiration' 
                                disabled={formikProps.values.canRenew?.code === "NO"} inputProps={{ min: 0, onClick: (e) => e.currentTarget.select()}} />
                        </FluidForEditors>
                        <FluidForEditors inRow={2}>                            
                            <DictionaryFieldByCode required name="approverType" label="Approver" query={{ key: 'RequirementApprover' }} dependandFieldName='rule'/>
                            <DictionaryFieldByCode
                                required={formikProps.values.approverType?.code === "1"}
                                hidden={formikProps.values.approverType?.code !== "1"}
                                name="approverGroup" label="Approver Group"
                                query={{ key: 'RequirementApproverGroup', division: form.values.division }}
                                />
                            <DictionaryFieldByCode 
                                name="rule" 
                                label="Rule" 
                                hidden={formikProps.values.approverType?.code !== "6"}
                                query={{ key: 'RequirementRule' }}                                 
                                required={formikProps.values.approverType?.code === "6"}
                                disabled={formikProps.values.approverType?.code !== "6"}/>
                            <>
                                <DictionaryFieldByCode 
                                    hidden={formikProps.values.rule?.code != "TRAINING"} 
                                    required name="training" label="MyLearning Course" 
                                    query={({ key: 'RequirementTraining' })}  />
                                <DictionaryFieldByCode
                                    hidden={formikProps.values.rule?.code != "CERTIFICATION"}
                                    required name="certification" label="Certification"
                                    query={({
                                        key: 'RequirementCertification',
                                        division: form.values.division
                                    })}  />
                                <DictionaryFieldByCode
                                    hidden={formikProps.values.rule?.code != "UPSKILL"}
                                    required name="upskill" label="Upskill Course"
                                    query={({ key: 'RequirementUpskill' })} />
                            </>
                        </FluidForEditors>
                        <Grid container spacing={2}>                        
                            <Grid item xs={12} lg={2}>                            
                            <FormControlLabel label="Add External Link" labelPlacement="end" style={{paddingTop: 15}}
                                control={<Checkbox checked={showLinks} onChange={() => setShowLinks(!showLinks)} />}
                            />                       
                            </Grid>
                            <Grid item xs={12} lg={5}>
                               {showLinks && <FormikTextField name="externalLinkDescription" label="Link Name" required /> }
                            </Grid>
                            <Grid item xs={12} lg={5}>
                               {showLinks && <FormikTextField name="externalLink" label="Link Address" required /> }
                            </Grid>                            
                        </Grid>
                        <FormControlLabel label="Mandatory Attachment" labelPlacement="end" style={{paddingTop: 15}}
                             name="isAttachmentMandatory" onChange={formikProps.handleChange}
                             control={<Checkbox checked={formikProps.values.isAttachmentMandatory} />}
                        /> 
                        <ActionsRoot>
                            <Button onClick={cancel}>
                                Cancel
                            </Button>
                            <Button color="secondary" onClick={formikProps.submitForm}>
                                {isNew ? "Add" : "Save"}
                            </Button>
                        </ActionsRoot>
                    </>
                    )}
                </Formik>)
        }}</FieldArray>
}
