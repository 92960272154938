import { useQuery } from 'react-query';

import { certificationListApi } from 'CertificationList/service/certificationList.api';
import { PlanRequirements } from 'Requirement/model/PlanRequirements';
import { CertificationPlanHistory } from 'CertificationPlansPage/model/CertificationPlanHistory';
import { CertificationPlansDetails, CertificateShortDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';
import { UseQueryOptions } from 'react-query/types/react/types';

export const certificationListDetailsKey = 'certification-list-details';
export const useCertificationListDetailsQuery = (certificationId: number) =>
  useQuery<CertificationPlansDetails>(
    [certificationListDetailsKey, certificationId],
    () => certificationListApi.get(certificationId)
  );

  export const certificateShortDetailsKey = 'certificate-short-details';
  export const useCertificateShortDetailsQuery = (certificateId: number) =>
    useQuery<CertificateShortDetails>(
      [certificateShortDetailsKey, certificateId],
      () => certificationListApi.getShort(certificateId)
    );  

export const certificationListRequirementsKey =
  'certification-list-requirements';
export const useCertificationListRequirementsQuery = (
  certificationId: number
) =>
  useQuery<PlanRequirements>(
    [certificationListRequirementsKey, certificationId],
    () => certificationListApi.getRequirements(certificationId)
  );

export const certificationListHistoryKey = 'certification-list-history';
export const useCertificationListHistoryQuery = (
  certificationId: number,
  options: UseQueryOptions<CertificationPlanHistory[]> = {}
) =>
  useQuery<CertificationPlanHistory[]>(
    [certificationListHistoryKey, certificationId],
    () => certificationListApi.getHistory(certificationId),
    options
  );

export const enrollRequestDetailsKey = 'enroll-request-details';
export const useCertificationEnrollRequestQuery = (certificationId: number) =>
  useQuery<CertificationPlansDetails>(
    [enrollRequestDetailsKey, certificationId],
    () => certificationListApi.getEnrollRequest(certificationId)
  );
