import { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
  MsalAuthProvider,
} from 'react-aad-msal';

import * as routes from 'route';
import { authProvider, queryClient } from 'service';
import { AppShell } from 'components/AppShell/AppShell';
import { Login, LoginMock } from 'components/Login';
import { MyProfilePage } from 'page/MyProfilePage/MyProfilePage';
import { HelpAndSupportPage } from 'page/HelpAndSupportPage/HelpAndSupportPage';
import { MyCertificationsPage } from 'page/MyCertificationsPage/MyCertificationsPage';
import { MOCK_LOGIN } from 'featureToggles';
import { ToastProvider } from 'components/ToastProvider';
import { CertificationsByPersonPage } from 'page/CertificationsByPersonPage';
import { CertificationPlansDetailsPage } from 'page/CertificationPlansDetailsPage';
import { PendingApprovalsPage } from 'page/PendingApprovalsPage';
import { CatalogPage } from 'page/CatalogPage';
import {
  PrintOutUserCertificatesPage,
  PrintOutMyCertificatesPage,
} from 'page/PrintOutCertificatesPage';
import { CatalogDetailsPage } from 'page/CatalogDetailsPage';
import { CertificationPlansEnrollRequestDetailsPage } from 'page/CertificationPlansEnrollRequestDetailsPage';
import { ErrorDetailsAppShell, QueryErrorResetBoundary } from 'components';
import { CertificationsByPersonDetailsPage } from 'page/CertificationsByPersonDetailsPage';
import { CertificationsByPersonMyLearningPage } from 'page/CertificationsByPersonMyLearningPage';
import { StatusOfCertificationsPage } from 'page/StatusOfCertificationsPage';
import { UncompletedRequirementsPage } from 'page/UncompletedRequirementsPage';
import { MassEnrollmentPage } from 'page/MassEnrollmentPage/MassEnrollmentPage';
import { BulkUploadPage } from 'page/BulkUploadPage/BulkUploadPage';
import { ExternalUsersPage } from 'page/ExternalUsersPage/ExternalUsersPage';
import { InternalUsersPage } from 'page/InternalUsersPage/InternalUsersPage';
import { ArchivedUsersPage } from 'page/ArchivedUsersPage/ArchivedUsersPage';
import { AddInternalUsersPage } from 'page/AddInternalUsersPage';
import { AddExternalUsersPage } from 'page/AddExternalUsersPage';
import { ApprovalGroupsPage } from 'page/ApprovalGroupsPage';
import { ApprovalGroupDetailsPage } from 'page/ApprovalGroupsDetailsPage';
import { ManageCompaniesPage } from 'page/ManageCompanies';
import { ManageScopeDetailsPage } from 'page/ManageScopeDetails';
import { DevelopmentPage } from 'page/Dev';
import { DigitalSignaturePage, PrintingStandardsPage, PrintingHeadersPage } from 'page/PrintingCertificates';
import {
  ManageCertificatesPage,
  ManageCertificatesDetailsPage,
  ManageTrainingSetPage,
  TrainingSetDetailsPage
} from 'page/ManageCertificates';
import { OldEnrollmentsPage } from 'page/OldEnrollmentsPage';
import { OldEnrollmentsDetailsPage } from 'page/OldEnrollmentsDetailsPage';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { notFoundRoute } from 'route';
import { ProfileLoader } from './ProfileLoader';
import { PCSContextProvider } from './PCSContext';

export const Router: FC = () => (
  <ToastProvider>
    <AzureAD provider={authProvider as MsalAuthProvider}>
      {({
        login,
        logout,
        accountInfo,
        authenticationState,
        error,
      }: IAzureADFunctionProps) => {
        const handleLogout = () => {
          queryClient.clear();
          logout();
        };
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return (
              <PCSContextProvider onLogout={handleLogout} user={accountInfo?.account.userName!}>
                <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                    <QueryErrorResetBoundary>
                      <ProfileLoader
                        renderContent={(userNeedToUpdateProfile) => (
                          <Switch>
                            <Route
                              exact
                              path={[
                                routes.development.path,
                                routes.myCertificationsRoute.path,
                                routes.helpAndSupportRoute.path,
                                routes.myProfileRoute.path,
                                routes.catalogRoute.path,
                                routes.certificationsByPersonRoute.path,
                                routes.pendingApprovalsRoute.path,
                                routes.massEnrollmentRoute.path,
                                routes.bulkUploadRoute.path,
                                routes.certificationsStatusRoute.path,
                                routes.uncompletedRequirementsRoute.path,
                                routes.externalUsersRoute.path,
                                routes.internalUsersRoute.path,
                                routes.approvalGroupsRoute.path,
                                routes.archivedUsersRoute.path,
                                routes.manageCompaniesRoute.path,
                                routes.manageCertificatesRoute.path,
                                routes.trainingSetsRoute.path,
                                routes.oldEnrollmentsRoute.path,
                                routes.scopeKeywordsRoute.path,
                                routes.digitalSignatureRoute.path,
                                routes.printingStandardRoute.path,
                                routes.printingHeadersRoute.path
                              ]}
                              render={() => (
                                <AppShell
                                  logout={handleLogout}
                                  userNeedToUpdateProfile={
                                    userNeedToUpdateProfile
                                  }
                                >
                                  <Switch>
                                    {process.env.NODE_ENV === 'development' && (
                                      <Route
                                        exact
                                        path={routes.development.path}
                                      >
                                        <DevelopmentPage />
                                      </Route>
                                    )}
                                    <Route
                                      exact
                                      path={routes.myCertificationsRoute.path}
                                    >
                                      <MyCertificationsPage />
                                    </Route>
                                    <Route exact path={routes.catalogRoute.path}>
                                      <CatalogPage />
                                    </Route>
                                    <Route
                                      exact
                                      path={routes.helpAndSupportRoute.path}
                                    >
                                      <HelpAndSupportPage />
                                    </Route>
                                    <Route
                                      exact
                                      path={routes.myProfileRoute.path}
                                    >
                                      <MyProfilePage />
                                    </Route>
                                    <ProtectedRoute
                                      exact
                                      path={
                                        routes.certificationsByPersonRoute.path
                                      }
                                      roles={
                                        routes.certificationsByPersonRoute.role
                                      }
                                    >
                                      <CertificationsByPersonPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.pendingApprovalsRoute.path}
                                      roles={routes.pendingApprovalsRoute.role}
                                    >
                                      <PendingApprovalsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.massEnrollmentRoute.path}
                                      roles={routes.massEnrollmentRoute.role}
                                    >
                                      <MassEnrollmentPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.bulkUploadRoute.path}
                                      roles={routes.bulkUploadRoute.role}
                                    >
                                      <BulkUploadPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.certificationsStatusRoute.path}
                                      roles={
                                        routes.certificationsStatusRoute.role
                                      }
                                    >
                                      <StatusOfCertificationsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={
                                        routes.uncompletedRequirementsRoute.path
                                      }
                                      roles={
                                        routes.uncompletedRequirementsRoute.role
                                      }
                                    >
                                      <UncompletedRequirementsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.externalUsersRoute.path}
                                      roles={routes.externalUsersRoute.role}
                                    >
                                      <ExternalUsersPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.internalUsersRoute.path}
                                      roles={routes.internalUsersRoute.role}
                                    >
                                      <InternalUsersPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.approvalGroupsRoute.path}
                                      roles={routes.approvalGroupsRoute.role}
                                    >
                                      <ApprovalGroupsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.archivedUsersRoute.path}
                                      roles={routes.archivedUsersRoute.role}
                                    >
                                      <ArchivedUsersPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.manageCompaniesRoute.path}
                                      roles={routes.manageCompaniesRoute.role}
                                    >
                                      <ManageCompaniesPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.scopeKeywordsRoute.path}
                                      roles={routes.scopeKeywordsRoute.role}
                                    >
                                      <ManageScopeDetailsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.oldEnrollmentsRoute.path}
                                      roles={routes.oldEnrollmentsRoute.role}
                                    >
                                      <OldEnrollmentsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.digitalSignatureRoute.path}
                                      roles={routes.digitalSignatureRoute.role}
                                    >
                                      <DigitalSignaturePage />
                                    </ProtectedRoute>


                                    <ProtectedRoute
                                      exact
                                      path={routes.manageCertificatesRoute.path}
                                      roles={routes.manageCertificatesRoute.role}
                                    >
                                      <ManageCertificatesPage />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                      exact
                                      path={routes.trainingSetsRoute.path}
                                      roles={routes.trainingSetsRoute.role}
                                    >
                                      <ManageTrainingSetPage />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                      exact
                                      path={routes.printingStandardRoute.path}
                                      roles={routes.printingStandardRoute.role}
                                    >
                                      <PrintingStandardsPage />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                      exact
                                      path={routes.printingHeadersRoute.path}
                                      roles={routes.printingHeadersRoute.role}
                                    >
                                      <PrintingHeadersPage />
                                    </ProtectedRoute>
                                  </Switch>
                                </AppShell>
                              )}
                            />
                            <Route
                              exact
                              path={routes.certificationPlansDetailsRoute.path}
                            >
                              <CertificationPlansDetailsPage
                                logout={handleLogout}
                              />
                            </Route>
                            <Route
                              exact
                              path={
                                routes.certificationPlansEnrollRequestDetailsRoute
                                  .path
                              }
                            >
                              <CertificationPlansEnrollRequestDetailsPage
                                logout={handleLogout}
                              />
                            </Route>
                            <Route exact path={routes.catalogDetailsRoute.path}>
                              <CatalogDetailsPage logout={handleLogout} />
                            </Route>
                            <ProtectedRoute exact path={routes.oldEnrollmentsDetailsRoute.path} roles={routes.oldEnrollmentsDetailsRoute.role}>
                              <OldEnrollmentsDetailsPage logout={handleLogout} />
                            </ProtectedRoute>
                            <Route exact path={routes.manageCertificatesDetailsRoute.path}>
                              <ManageCertificatesDetailsPage />
                            </Route>

                            <Route exact path={routes.managTrainingSetDetailsRoute.path}>
                              <TrainingSetDetailsPage />
                            </Route>
                            <ProtectedRoute
                              exact
                              path={
                                routes.certificationsByPersonDetailsRoute.path
                              }
                              roles={
                                routes.certificationsByPersonDetailsRoute.role
                              }
                            >
                              <CertificationsByPersonDetailsPage
                                logout={handleLogout}
                              />
                            </ProtectedRoute>
                            <ProtectedRoute
                              exact
                              path={
                                routes.certificationsByPersonMyLearningRoute.path
                              }
                              roles={
                                routes.certificationsByPersonMyLearningRoute.role
                              }
                            >
                              <CertificationsByPersonMyLearningPage
                                logout={handleLogout}
                              />
                            </ProtectedRoute>
                            <Route
                              exact
                              path={routes.printOutMyCertificatesRoute.path}
                            >
                              <PrintOutMyCertificatesPage logout={handleLogout} />
                            </Route>
                            <ProtectedRoute
                              exact
                              path={
                                routes
                                  .certificationsByPersonPrintOutCertificatesRoute
                                  .path
                              }
                              roles={
                                routes
                                  .certificationsByPersonPrintOutCertificatesRoute
                                  .role
                              }
                            >
                              <PrintOutUserCertificatesPage
                                logout={handleLogout}
                              />
                            </ProtectedRoute>
                            <ProtectedRoute
                              exact
                              path={routes.addInternalUsersRoute.path}
                              roles={routes.addInternalUsersRoute.role}
                            >
                              <AddInternalUsersPage logout={handleLogout} />
                            </ProtectedRoute>
                            <ProtectedRoute
                              exact
                              path={routes.addExternalUsersRoute.path}
                              roles={routes.addExternalUsersRoute.role}
                            >
                              <AddExternalUsersPage logout={handleLogout} />
                            </ProtectedRoute>
                            <ProtectedRoute
                              exact
                              path={routes.approvalGroupsDetailsRoute.path}
                              roles={routes.approvalGroupsDetailsRoute.role}
                            >
                              <ApprovalGroupDetailsPage logout={handleLogout} />
                            </ProtectedRoute>
                            <Route path={[notFoundRoute, '*']}>
                              <ErrorDetailsAppShell
                                errorCode={404}
                                errorMsg="We are sorry, but we couldn't find the page you are looking for."
                              />
                            </Route>
                          </Switch>
                        )}
                      />
                    </QueryErrorResetBoundary>
                  </BrowserRouter>
                  {process.env.NODE_ENV === 'development' && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                </QueryClientProvider>
              </PCSContextProvider>
            );
          case AuthenticationState.InProgress:
            return <>Loading...</>;
          case AuthenticationState.Unauthenticated:
          default:
            return MOCK_LOGIN ? (
              <LoginMock login={login} />
            ) : (
              <Login login={login} />
            );
        }
      }}
    </AzureAD>
  </ToastProvider>
);
