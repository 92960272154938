import { Dispatch } from 'react';
import { DictionaryField, FluidForEditors, SearchFiltersFormSmart } from 'components';
import { API } from './model';

import * as Model from './model';

export default function FiltersPart(p: {
  defaultQuery: Model.TrainingSetFindQueryDTO
  onQuery: Dispatch<Model.TrainingSetFindQueryDTO>;
}) {

  return (
    <SearchFiltersFormSmart
      placeholder="Search by Name ..."
      searchBarField="name"
      initialValues={p.defaultQuery}
      onSubmit={p.onQuery}
    >
      {(fp) => (
        <FluidForEditors inRow={2}>
          <DictionaryField name="division" label="Division" query={API.dictionaryQuery({ key: 'Division' })} />
          <DictionaryField name="productGroup" label="Product Group" query={API.dictionaryQuery({ key: 'ProductGroup', division: fp.values.division })} />
          <DictionaryField name="functionalJobFamily" label="Employee Job Family" query={API.dictionaryQuery({ key: 'FunctionalJobFamily' })} />
          <DictionaryField name="functionalJob" label="Employee Job" query={API.dictionaryQuery({ key: 'FunctionalJob' })} />
          <DictionaryField name="country" label="Country/Territory" query={API.dictionaryQuery({ key: 'Country' })} />
          <DictionaryField name="userType" label="ABB / External" query={API.dictionaryQuery({ key: 'UserType' })} />
        </FluidForEditors>
      )}
    </SearchFiltersFormSmart>
  );
}
