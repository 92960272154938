import { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { Field, FieldProps as FormikFieldProps, useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HelperIcon from '@material-ui/icons/Info';
import { HelperTextRoot, HelperTypography } from 'components/Field/AutocompleteField/Autocomplete.style';
import { API as CAPI } from 'page/ManageCertificates/Certificate/model';//$todo$ refactor this
import { FieldProps } from './common';

export interface DictionaryFieldPropsBase<TDataModel> extends FieldProps<TDataModel> {
    multiple?: boolean;
    required?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    blockSort?: boolean;
}

export interface DictionaryFieldProps<TDataModel> extends DictionaryFieldPropsBase<TDataModel> {
    query?: UseQueryResult<DictionaryItemByCodeDTO[]>;
    dependandFieldName?: string;
}
export interface DictionaryFieldPropsAdvanced<TQueryItemModel> extends DictionaryFieldPropsBase<Record<string, unknown>> {
    query: (query: { searchFor: string }) => UseQueryResult<TQueryItemModel[]>
    displayProperty: (keyof TQueryItemModel & string)
}

export interface DictionaryFieldByCodeProps<TDataModel> extends DictionaryFieldPropsBase<TDataModel> {
    query: DictionaryContextQueryDTO
    dependandFieldName?: string;
}

export function DictionaryFieldByCode<TDataModel>(p: DictionaryFieldByCodeProps<TDataModel>) {
    return DictionaryField<TDataModel>({ ...p, query: CAPI.ICertificateListContract.dictionaryQuery(p.query) })
}

export function DictionaryField<TDataModel>(p: DictionaryFieldProps<TDataModel>) {
    const { data = [], isLoading, refetch } = p.query ?? { data: [], isLoading: false, refetch: () => { } };
    const [field, meta, helpers] = useField({ name: p.name });
    const helperText = meta.error ?? p.helperText
    const renderHelperText = (touched: boolean) =>
        !!helperText && touched && (
            <HelperTextRoot>
                {meta.error ? <HelperIcon color='secondary' fontSize="small" /> : <></>}
                <HelperTypography variant="caption">{helperText}</HelperTypography>
            </HelperTextRoot>
        );
    return (<Field name={p.name} key={p.name} >
        {(helper: FormikFieldProps) => (<>
            <Autocomplete
                hidden={p.hidden}
                disableClearable
                disabled={p.disabled}
                options={p.blockSort ? data : data.sort((a, b) => (a.display).localeCompare(b.display))}
                multiple={p.multiple}
                loading={isLoading}
                onOpen={() => data.length > 0 || refetch()}

                onChange={(e, v) => {
                    setFieldLogic(helper, p, v);
                }}
                value={helper.field.value ?? (p.multiple ? [] : null)}
                getOptionLabel={(option) => option?.display ?? ""}
                getOptionSelected={(option, value) => option.id === value?.id}
                noOptionsText="No data found"

                renderInput={(params) => (
                    <TextField

                        {...params}
                        {...p}

                        error={!!meta.error}
                        helperText={ renderHelperText(helper.meta.touched)}
                        required={false}
                        InputLabelProps={{ required: p.required }}
                        label={p.label}
                        variant="filled" />)}
            />
        </>
        )}
    </Field>);
}

// eslint-disable-next-line
function setFieldLogic<TDataModel>(helper: FormikFieldProps, p: DictionaryFieldProps<TDataModel>, v:any){
    helper.form.setFieldValue(p.name, v);
    if(p.dependandFieldName) {
        p.dependandFieldName.split("|").forEach(x => helper.form.setFieldValue(x, undefined));        
    }
}

export function DictionaryFieldAdvanced<TQueryItemModel extends object>(p: DictionaryFieldPropsAdvanced<TQueryItemModel>) {
    const searchTimeout = 200;
    const minLenOfSearchValue = 3;
    const initialValue = "";

    //@ts-ignore as-is
    const getId = (item: TQueryItemModel) => (item && "id" in item) ? item.id : "<UNKNOWN_KEY>"

    const [field, meta] = useField({ name: p.name });
    const [searchFor, setSearchFor] = useState(initialValue);
    const { data = [], isLoading, refetch } = p.query({ searchFor })

    const helperText = meta.error ?? p.helperText
    const renderHelperText = () =>
        !!helperText && (
            <HelperTextRoot>
                {meta.error ? <HelperIcon color='secondary' fontSize="small" /> : <></>}
                <HelperTypography variant="caption">{helperText}</HelperTypography>
            </HelperTextRoot>
        );

    useEffect(() => {
        if (initialValue !== searchFor && searchFor.length >= minLenOfSearchValue) {
            const timer = setTimeout(refetch, searchTimeout);
            return () => clearTimeout(timer);
        }
        return undefined
    }, [searchFor]);


    return (<Field name={p.name} key={p.name} >
        {(helper: FormikFieldProps) => (<>
            <Autocomplete
                hidden={p.hidden}
                disabled={p.disabled}
                options={data.sort((a, b) => (a[p.displayProperty] as unknown as string).localeCompare(b[p.displayProperty] as unknown as string))}
                multiple={p.multiple}
                loading={isLoading}
                //onOpen={() => data.length > 0 || refetch()}

                onChange={(e, v) => {
                    helper.form.setFieldValue(p.name, v);
                }}
                value={helper.field.value ?? (p.multiple ? [] : null)}
                getOptionLabel={option => option[p.displayProperty]}
                getOptionSelected={(option, value) => value && (getId(option) === getId(value))}
                noOptionsText={searchFor && searchFor.length > minLenOfSearchValue ? "No data found" : `Minimum ${minLenOfSearchValue} characters required`}

                renderInput={(params) => (
                    <TextField

                        {...params}
                        {...p}

                        error={!!meta.error}
                        helperText={renderHelperText()}
                        required={false}
                        InputLabelProps={{ required: p.required }}
                        onChange={(event) => setSearchFor(event.target.value)}
                        label={p.label}
                        variant="filled" />)}
            />
        </>
        )}
    </Field>);

};
