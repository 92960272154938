import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { Card } from 'components/Page';
import { useToasts } from 'components/ToastProvider';
import { CPStatusChip } from 'components/StatusChip';
import { OldEnrollmentDetailsUsers } from 'OldEnrollments/model/OldEnrollmentResult';
import { OldEnrollmentActionResponse } from 'OldEnrollments/model/OldEnrollmentActionResponse';
import { oldEnrollmentsApi } from "OldEnrollments/service/oldEnrollments.api";
import { Grid, IconButton, Button, Typography, Accordion, AccordionDetails, AccordionSummary, Checkbox, useTheme, FormControlLabel, Tooltip } from '@material-ui/core';
import UsedIcons from 'components/Trivial/UsedIcons';
import { NavigateNext, ExpandMore, Settings } from '@material-ui/icons';
import { formatDate } from 'utils';
import { ApiError } from 'model';
import { certificationPlansDetailsRoute } from 'route';

export const detailsColumns: DataTableColumn<OldEnrollmentDetailsUsers>[] = [
  {
    field: 'user',
    headerName: 'User',
    width: 200,
    renderField: (u) => (
      <>
        <Typography variant="body2">{`${u.user.firstName} ${u.user.lastName}`}</Typography>
        <Typography variant="caption">{u.user.email}</Typography>
      </>
    ),
  },
  {
    field: 'userDivision',
    headerName: 'Division',    
  },
  {
    field: 'userCompany',
    headerName: 'Company',    
  },
  {
    field: 'userCountry',
    headerName: 'Country',    
  },
  {
    field: 'enrollmentDate',
    headerName: 'Enrollment',    
    renderField: ({ enrollmentDate }) =>
      formatDate(enrollmentDate),
  },
  {
    field: 'approvalDate',
    headerName: 'Approval',    
    renderField: ({ approvalDate }) =>
      formatDate(approvalDate),
  },
  {
    field: 'certificateStatus',
    headerName: 'Status',    
    renderField: ({ certificateStatus }) => <CPStatusChip status={certificateStatus} />,
  },
  {
    field: 'requirementCount',
    headerName: 'Requirements',    
  },
];

export interface OldEnrollmentsDetailsTableProps{  
  displayRowActions: boolean;
  onActionSuccess: () => void;
  DataTableProps: Omit<DataTableProps<OldEnrollmentDetailsUsers>, 'columns'>;
}

export const OldEnrollmentsDetailsTable: FC<OldEnrollmentsDetailsTableProps> = ({
  DataTableProps, 
  onActionSuccess
}) => {

  const [inProcessOnly, setInProcessOnly] = useState(false);
  const [skipEnrollment, setSkipEnrollment] = useState(false);
  const [noNotifications, setNoNotifications] = useState(false);
  const { showSuccess, showInfo, showError, showMany } = useToasts();
  const theme = useTheme();
  const handleOpenDetails = (enrollmentId: number) => () =>
    window.open(
      `${window.location.origin}${certificationPlansDetailsRoute.createRoute({
        certificationPlanId: String(enrollmentId),
      })}`,
      '_blank'
    );

  const { mutate: remove } = useMutation<
    unknown,
    ApiError,
    string[]
  >((ids) => oldEnrollmentsApi.remove({ certificateIds: ids.map((id) => Number(id)), inProcessOnly: inProcessOnly }), {
    onSuccess: (response) => { onActionSuccess(); showSuccess('Certifications have been removed') },
    onError: (error) => { showError('Remove action failed') }
  });


  const { mutate: replace } = useMutation<
    OldEnrollmentActionResponse,
    ApiError,
    { ids: string[], action: string }
  >(({ ids, action }) => oldEnrollmentsApi.replace({ 
      certificateIds: ids.map((id) => Number(id)), 
      action: action,
      inProcessOnly: inProcessOnly, 
      noNotifications: noNotifications,
      skipEnrollment: skipEnrollment
    }), {
      onSuccess: (response) => { 
        onActionSuccess(); 
        if (response.failed.length && !response.success.length) {          
          showMany('All enrollments failed, certifications were restored to previous status', 
          response.failed.map(response => `User ${response.userEmail} was not enrolled with message: ${response.message}`)
          , true);          
        }
        else if (!response.failed.length && response.success.length) {
          showSuccess('Users have been enrolled to Certifications');
        }
        else if (response.failed.length && response.success.length) {
          showSuccess('Users have been enrolled to Certifications');
          showMany('Some enrollments failed, certifications were restored to previous status', 
          response.failed.map(response => `User ${response.userEmail} was not enrolled with message: ${response.message}`)
          , true);          
        }
        else {
          showInfo('No certifications were processed');
        }
      },
      onError: (error) => showError(`Replace action failed with message: ${error.message}`),
  });

  return ( 
    <>
      <Accordion 
        elevation={0}
        style={{
          marginTop: theme.spacing(2),
          borderRadius: theme.shape.borderRadius,
        }}
        >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Settings style={{ marginRight: theme.spacing(2) }} />
          <Typography variant="h6">Additional settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
          <Grid item xs={12} lg={4}>
            <FormControlLabel label="Certifications in status In Process only" labelPlacement="end" style={{paddingTop: 15}} 
              control={<Checkbox checked={inProcessOnly} onChange={() => setInProcessOnly(!inProcessOnly)} />}
            /> 
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControlLabel label="Skip enrollment approval step" labelPlacement="end" style={{paddingTop: 15}} 
              control={<Checkbox checked={skipEnrollment} onChange={() => setSkipEnrollment(!skipEnrollment)} />}
            /> 
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControlLabel label="Do not send notifications" labelPlacement="end" style={{paddingTop: 15}} 
              control={<Checkbox checked={noNotifications} onChange={() => setNoNotifications(!noNotifications)} />}
            /> 
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Card header={`Search results: ${DataTableProps.rows?.length ?? '...' }`} >
        <DataTable
          columns={detailsColumns}
          rowSelector={(row) => row.enrollmentId}
          renderSelectedActions={(selectedRows, clearSelected) => (
            <>
              <Button onClick={() => { remove(selectedRows); showInfo('Remove action queued'); clearSelected();}}>
                Remove 
              </Button>
              <Button color="secondary" onClick={() => { replace({ action: "replace", ids: selectedRows }); showInfo('Replace action queued'); clearSelected();} }>
                Replace with the newest
              </Button>
              <Button color="secondary" onClick={() => { replace({ action: "recheck", ids: selectedRows }); showInfo('Replace action queued'); clearSelected();} }>
                Replace and recheck 
              </Button>
              <Button color="secondary" onClick={() => { replace({ action: "keepvalid", ids: selectedRows }); showInfo('Replace action queued'); clearSelected();} }>
                Replace and keep valid
              </Button>       
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>
                {`REMOVE
                It removes enrollments from old version of Certification (no further action).\n
                REPLACE WITH THE NEWEST
                It removes enrollments from old version + enrolls to new version (the  statuses of Requirements and Certifications are reset).\n
                REPLACE AND RECHECK
                It removes enrollments from old version + enrolls to new one + it transfers the  status of the requirements (provided that the Requirement code is the same).\n
                REPLACE AND KEEP VALID
                It removes enrollments from old version + enrolls to new one + it transfers the  status of the requirements (provided that the Requirement code is the same).
                If the status of the Certification is completed & the Certification is valid then the 'completed' status will be transferred to new enrollment. The other statuses will be transferred as 'In process'.`}
              </div>}>
              <UsedIcons.HelperIcon />
              </Tooltip>
            </>
          )}
          renderRowActions={({ enrollmentId }) =>
            (
              <IconButton onClick={handleOpenDetails(enrollmentId)}>
                <NavigateNext />
              </IconButton>
            )
          }
          hideCollapseColumn
          {...DataTableProps}
        />
      </Card>
    </>    
  )
};

