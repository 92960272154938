import { Dispatch } from 'react'
import { Typography } from '@material-ui/core';
import { FormikForApp } from 'components/FormikField';
import { BusinessUnitSearchBar } from 'ApprovalGroupsPage/components';
import { ReactComponent as NotFoundSvg } from '../../../components/QueryErrorResetBoundary/svg/NotFound.svg';


import * as Model from './model'

type QueryModel = Model.PrintingStandardTextsFindQuery

export default function SearchForm(p: {
  defaultQuery: QueryModel
  onQuery: Dispatch<QueryModel>
}) {

  return (
    <FormikForApp validate={p.onQuery} initialValues={p.defaultQuery} onSubmit={p.onQuery}>
      {(p) => 
        <>
        <BusinessUnitSearchBar onBuChange={(value) => p.setFieldValue("division", value)} />        
        { !p.values.division && 
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <NotFoundSvg />
          <Typography variant="h6">
            Select Division first...
          </Typography>
          </div>
        }
        </>
      }
    </FormikForApp>)
}
