import React, { DispatchWithoutAction } from 'react';
import { usePCSContext, ModalQuetionProps } from 'Router/PCSContext';
import { Formik, FieldArray, useField } from 'formik';
import { Yup, FormikCheckboxField, EditorHelper } from 'components/FormikField';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
    IconButton,
    Button, Typography, Card, Stack,
    DataTable, UsedIcons, FluidForEditors,
    ActionsRoot, DictionaryField, DataTableColumn
} from 'components';
import { API, KnownCertificateListStatus } from './model';

import * as Model from './model';

type TRootModel = Model.CertificateListDefinitionDTO
type TItemModel = Model.CertificateListPrintIdentificators

export function PrintOptionsCardPart(p: { helper: EditorHelper<Partial<TRootModel>>; }) {
    const disablePreviews = p.helper.values.status?.code == KnownCertificateListStatus.Temporary ?? false;
    const showAddPurpose = p.helper.values.division?.code === "ELDS" || p.helper.values.division?.code === "ELSE";

    const [state, setState] = React.useState<{ adding: boolean; }>({ adding: false });
    const [field] = useField({ name: "id" });
    function doPreview(forExternal: boolean) {
        API.ICertificateListContract
            .preview({ forExternal, id: field.value })
    }

    const onBeginAdd = () => setState({ ...state, adding: true });
    const onCancelAdd = () => setState({ ...state, adding: false });

    return (<Stack direction='column'>
        <Card header="Print preview">
            {/* <FormikCheckboxField name="printPurposeOnPdf" label="Add Purpose to PDF?"
                hidden={!showAddPurpose}
                // helperText="Configured Certificate Headers will be included in the printed certificate."
            /> */}
            {showAddPurpose && <FormControlLabel label="Add Purpose to PDF?" labelPlacement="end" style={{paddingTop: 15}}
                             name="printPurposeOnPdf" onChange={p.helper.handleChange} disabled={!showAddPurpose}
                             control={<Checkbox checked={p.helper.values.printPurposeOnPdf}  disabled={!showAddPurpose} />}
                        /> 
            }
            <ActionsRoot>
                <Button color="primary" disabled={disablePreviews} onClick={() => doPreview(false)}>
                    PREVIEW FOR ABB
                </Button>
                <Button color="primary" disabled={disablePreviews} onClick={() => doPreview(true)}>
                    PREVIEW FOR EXTERNAL
                </Button>
            </ActionsRoot>
        </Card>

        {!state.adding ?
            <ActionsRoot>
                <Button color="secondary" onClick={onBeginAdd}>
                    Add Capability
                </Button>
            </ActionsRoot>
            :
            <Card header='New Capability'>
                <Typography variant="body2" display="block" style={{ whiteSpace: "pre-wrap" }}>
                    If you cannot see any Header it means that it is not defined for the Certificate’s Division.
                    <br />
                    <br />
                </Typography>
                <CapabilityPart onEnd={onCancelAdd} />
            </Card>}
        <Card>
            <IdentificatorsList />
        </Card>

    </Stack>);
}

function FormikDataTableField<TR, TItemModel>(p: {
    name: (keyof TR & string)
    columns: DataTableColumn<TItemModel>[]
    rowSelector: (item: TItemModel) => string | number,
    rowEditor: (item: TItemModel, onEnd: () => void) => JSX.Element,
    noDataMessage: string,
    deleteConfirmation: Omit<ModalQuetionProps, "onConfirmed">
}) {
    const pcsContext = usePCSContext()!;
    return <FieldArray name={p.name}>
        {arrayHelpers => {
            const items = arrayHelpers.form.values[arrayHelpers.name] as TItemModel[]
            function doDelete(item: TItemModel) {
                arrayHelpers.remove(items.indexOf(item));
            }

            function onDelete(item: TItemModel) {
                pcsContext.showQuestion({
                    ...p.deleteConfirmation,
                    onConfirmed: () => doDelete(item)
                });
            }
            return (
                <>
                    <DataTable
                        columns={p.columns}
                        rowSelector={p.rowSelector}
                        renderCollapseRow={p.rowEditor}
                        rows={(arrayHelpers.form.values[arrayHelpers.name] as TItemModel[])}
                        noDataMessage={p.noDataMessage}
                        renderRowActions={(item, collapseRow, isExpanded) => isExpanded ?  (
                            <IconButton
                              onClick={collapseRow}
                            >
                              <UsedIcons.Close />
                            </IconButton>
                          ) 
                          : (<>
                            <IconButton onClick={collapseRow}>
                                <UsedIcons.Edit />
                            </IconButton>
                            <IconButton onClick={() => onDelete(item)}>
                                <UsedIcons.Delete />
                            </IconButton></>)}
                        hideCollapseColumn />
                </>
            )
        }}
    </FieldArray>
}

function IdentificatorsList() {
    return <Card header="Capabilities">
    <FormikDataTableField<TRootModel, TItemModel>
        name='identificators'
        rowEditor={(i, e) => <CapabilityPart onEnd={e} item={i} />}
        rowSelector={x => `${x.header.code}-${x.subheader?.code ?? 0}`}
        noDataMessage="Currently there are no Capabilities defined. Select from the list and click “Add Capability” button to add one."
        deleteConfirmation={{
            confirmTitle: "Remove Capability?",
            confirmDescription: "This Capability will be removed from the list.",
            confirmButton: "REMOVE"
        }}
        columns={[
            { field: 'header', headerName: 'Header', renderField: (r) => r.header.display },
            { field: 'subheader', headerName: 'Subheader', renderField: (r) => r.subheader?.display ?? "" },
            { field: 'identifiers', headerName: 'Identifiers', renderField: (r) => r.identifiers?.length > 0 ? r.identifiers.map((i) => i.display).join(', ') : "No Identifiers defined. Please use button to the right." },
        ]}

    />
    </Card>
}

function CapabilityPart(p: {
    item?: TItemModel;
    onEnd: DispatchWithoutAction;
}) {
    const pcsContext = usePCSContext()!;
    const data: Partial<TItemModel> & Pick<TItemModel, "identifiers"> = p.item ?? {
        identifiers: []
    }
    const isNew = p.item === undefined
    const fieldName = 'identificators';
    return <FieldArray name={fieldName}>
        {arrayHelpers => {
            const { form } = arrayHelpers
            const cancel = p.onEnd
            const submit = (value: Partial<TItemModel>) => {
                const items = arrayHelpers.form.values[fieldName] as Partial<TItemModel>[];
                const currentIndex = items.indexOf(data);
                currentIndex == -1 ? arrayHelpers.push(value) : arrayHelpers.replace(currentIndex, value);
                p.onEnd();
            }
            return (
                <Formik
                    initialValues={data}
                    validationSchema={Yup.object().shape({
                        header: Yup.object().nullable().required(),
                    })}
                    onSubmit={submit}>
                    {formikProps => {
                        const x = 1
                        return (<>
                            {!isNew ?
                                <>
                                    <Card header="Add new Identifier" elevation={0}>
                                        <FluidForEditors inRow={1}>

                                            <DictionaryField required name="newIdentificator" label="Identifier" query={API.ICertificateListContract.dictionaryQuery({ key: 'CapabilityIdentifier', division: form.values.division })} />
                                            <ActionsRoot>
                                                <Button type="submit" color="secondary"
                                                    onClick={() => {
                                                        const items = arrayHelpers.form.values[fieldName] as TItemModel[];
                                                        const current = items.find(c => c.header.code === data.header?.code && c.subheader?.code === data.subheader?.code);
                                                        if (!current)
                                                            return;
                                                        // eslint-disable-next-line
                                                        const newItem = ((formikProps.values as any).newIdentificator) as DictionaryItemByCodeDTO;
                                                        if (!newItem)
                                                            return;
                                                        current.identifiers.push(newItem);
                                                        formikProps.setFieldValue("newIdentificator", null);
                                                    }}>
                                                    ADD IDENTIFIER
                                                </Button>
                                            </ActionsRoot>

                                        </FluidForEditors>
                                    </Card>
                                   

                                        <DataTable
                                            columns={[
                                                { field: "display", headerName: "Identifiers list" },
                                            ]}
                                            noDataMessage="Currently there are no Identifiers set up. Click “Add Identifier” button to add one."
                                            rows={data.identifiers}
                                            renderRowActions={(item) => (
                                                <IconButton onClick={() => pcsContext.showQuestion({
                                                    confirmTitle: "Remove Identifier?",
                                                    confirmDescription: "This Identifier will be removed from the list.",
                                                    confirmButton: "REMOVE",
                                                    onConfirmed: () => {
                                                        const current = data.identifiers.findIndex(c => c.code === item.code);
                                                        if (current < 0)
                                                            return;
                                                        data.identifiers.splice(current, 1);
                                                        formikProps.setFieldValue("identifiers", data.identifiers);
                                                    }
                                                })}>
                                                    <UsedIcons.Delete />
                                                </IconButton>)} />
                                </> :
                                <>
                                    <FluidForEditors inRow={2}>
                                        <DictionaryField required name="header" label="Header" query={API.ICertificateListContract.dictionaryQuery({ key: 'CapabilityHeader', division: form.values.division })} />
                                        <DictionaryField name="subheader" label="Subheader" query={API.ICertificateListContract.dictionaryQuery({ key: 'CapabilitySubheader', division: form.values.division })} />
                                    </FluidForEditors>                                    
                                    <ActionsRoot>
                                        <Button onClick={cancel}>
                                            Cancel
                                        </Button>
                                        <Button color="secondary" onClick={formikProps.submitForm}>
                                            {isNew ? "Add" : "Save"}
                                        </Button>
                                    </ActionsRoot>
                                </>}
                        </>
                        )
                    }}
                </Formik>
            )
        }}
    </FieldArray>;
}
