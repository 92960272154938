import { Yup, DictionaryFieldAdvanced, DictionaryFieldByCode, EditorHelper, FormikTextField } from 'components/FormikField';
import {
    Typography, Card, Stack,
    DataTable, FluidForEditors
} from 'components';
import { formatDate } from 'utils';
import { API, CertificateListDefinitionDTO } from './model';

export type DTOModel = CertificateListDefinitionDTO

export function LifecycleCardPart(p: { helper: EditorHelper<Partial<DTOModel>>; }) {

    const data = p.helper.values;

    return (<Stack>
        <Card header="Approvers">
            <FluidForEditors inRow={1}>
                <DictionaryFieldAdvanced name="owner" label="Owner" displayProperty="fullDisplayName" query={API.ICertificateListContract.findOwnerQuery} />
            </FluidForEditors>
            <FluidForEditors inRow={2}>
                <DictionaryFieldByCode required name="enrollmentApproverType" label="Enrollment Approver" query={({ key: 'EnrollmentApprover' })} />
                <DictionaryFieldByCode name="enrollmentApproverGroup"
                    hidden={data.enrollmentApproverType?.code !== "ApproverGroup"}
                    required={data.enrollmentApproverType?.code === "ApproverGroup"}
                    label="Enrollment Approver Group" helperText="Choose Enrollment Approver first" 
                    query={({ key: 'EnrollmentApproverGroup', division: data.division })} />
                <DictionaryFieldByCode required name="completionApproverType" label="Completion Approver" query={({ key: 'CompletionApprover' })} />
                <DictionaryFieldByCode name="completionApproverGroup"
                    hidden={data.completionApproverType?.code !== "ApproverGroup"}
                    required={data.completionApproverType?.code === "ApproverGroup"}
                    label="Completion Approver Group"  helperText="Choose Completion Approver first" 
                    query={({ key: 'EnrollmentApproverGroup', division: data.division })} />
                <DictionaryFieldByCode name="renewalApproverType" label="Renewal Approver" query={({ key: 'RenewalApprover' })} />
                <DictionaryFieldByCode name="renewalApproverGroup"
                    hidden={data.renewalApproverType?.code !== "ApproverGroup"}
                    required={data.renewalApproverType?.code === "ApproverGroup"}
                    label="Renewal Approver Group"  helperText="Choose Renewal Approver first" 
                    query={({ key: 'EnrollmentApproverGroup', division: data.division })} />
                <DictionaryFieldByCode required name="renewalEnrollmentApproverType" label="Renewal Enrollment Approver" query={({ key: 'RenewalEnrollmentApprover' })} 
                    hidden={data.renewalApproverType?.code === "NONE" || data.renewalApproverType?.code === "NORENEWAL"} />
                <DictionaryFieldByCode name="renewalEnrollmentApproverGroup"
                    hidden={data.renewalEnrollmentApproverType?.code !== "ApproverGroup"}
                    required={data.renewalEnrollmentApproverType?.code === "ApproverGroup"}
                    label="Renewal Enrollment Approver Group"  helperText="Choose Renewal Enrollment Approver first" 
                    query={({ key: 'EnrollmentApproverGroup', division: data.division })} />
            </FluidForEditors>
            <Typography variant="caption">Whenever an approver type is selected and does not exist (eg. Supervisor for a Country or Hierarchical Responsible),the approver is by default the Certification Authority</Typography>
        </Card>
        <Card header="Expiration and Renewal Policies">
            <FluidForEditors inRow={2}>
                <DictionaryFieldByCode required name="customExpirationDate" label="Expiration date" query={({ key: 'ExpirationDate' })} 
                    disabled={data.renewalApproverType?.code === "NONE"} />
                <FormikTextField name='expirationPeriod' label="Expiration period" helperText="month(s)" type="number" inputProps={{ min: 0, onClick: (e) => e.currentTarget.select()}}
                    disabled={data.customExpirationDate?.code !== "FIXED" || data.renewalApproverType?.code === "NONE"} 
                    />
                <FormikTextField name='renewalPeriod' label="Renewal period" helperText="month(s) before expiration" type="number" inputProps={{ min: 0, onClick: (e) => e.currentTarget.select()}} 
                    disabled={data.renewalApproverType?.code === "NONE" || data.renewalApproverType?.code === "NORENEWAL"} />
                <FormikTextField name='completePeriod' label="Due within" helperText="month(s) from enrollment" type="number" inputProps={{ min: 0, onClick: (e) => e.currentTarget.select()}} />
            </FluidForEditors>
            <FluidForEditors inRow={1}>
                <DictionaryFieldByCode name='renewalSurvey' label="Renewal Questionnaire" query={({ key: 'RenewalSurvey' })} 
                 disabled={data.renewalApproverType?.code === "NONE" || data.renewalApproverType?.code === "NORENEWAL"} />
            </FluidForEditors>
        </Card>
        <Card header='History'>
            <DataTable
                columns={[
                    { field: 'by', headerName: 'Author' },
                    { field: 'eventDate', headerName: 'Event Date', renderField: (x) => formatDate(x.eventDate) },
                    { field: 'description', headerName: 'Status' },
                ]}
                rows={(data.history ?? []).sort((a, b) => (a.eventDate).localeCompare(b.eventDate))} />
        </Card>
    </Stack>
    );
}
