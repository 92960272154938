import { Grid } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';
import {
  CatalogSearchFiltersForm,
  initialValues,
  mapCatalogSearchFilters,
} from 'Catalog/component/CatalogSearchFiltersForm/CatalogSearchFiltersForm';
import { CatalogSearchFilters } from "Catalog/model/CatalogSearchFilters";
import { OldEnrollmentsTable } from 'OldEnrollments/component/OldEnrollmentsTable';
import { useOldEnrollmentsQuery } from 'OldEnrollments/query/oldEnrollmentsQuery';
import { oldEnrollmentsApi } from "OldEnrollments/service/oldEnrollments.api";
import { useToasts, Button } from "components";
import { Content, Card } from 'components/Page';
import { TablePagination } from 'components/Table';
import { Loader } from 'components/Loader/Loader';
import { Pagination, getPagination } from "model";
import { useState } from "react";
import { useMutation } from "react-query";


export const OldEnrollmentsPage = () => {

  const { showSuccess, showError } = useToasts(); 
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<Pagination>(
    getPagination(pageSize)
  ); 
  const [filters, setFilters] = useState<Partial<CatalogSearchFilters>>(
    mapCatalogSearchFilters(initialValues)
  );
  const { isLoading, isFetching, data } = useOldEnrollmentsQuery(pagination, filters, {
    onSuccess: (newData) => setPagination(getPagination(pageSize, newData)),
  });
  const {
    mutate: exportToExcel,
    isLoading: isExportToExcelLoading,
  } = useMutation(() => oldEnrollmentsApi.exportToExcel(filters), {
    onSuccess: () => showSuccess('Export to excel success'),
    onError: () => showError('Export to excel failed. Too many records on search. Must be less than 10000.'),
  });

  return (
    <Content
      title="Manage Old Enrollments"
      subtitle="Manage enrollments from the previous releases of certificates"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CatalogSearchFiltersForm
                placeholder="Search by title or expand the filter on the right"
                renderPageActions={() =>
                    <Button
                      onClick={() => exportToExcel()}
                      disabled={isExportToExcelLoading}
                    >
                      {isExportToExcelLoading ? (
                        <Loader size={24} />
                      ) : (
                        <>
                          <ExportIcon /> &nbsp;Export to excel
                        </>
                      )}
                    </Button>
                }
              onFiltersSubmit={(catalogSearchFilters) => {
                setFilters(catalogSearchFilters);
                setPagination(getPagination(pageSize));
            }}        
          />
        </Grid>
        <Grid item xs={12}>
          <Card header={`Search results: ${data?.total ?? '...' }`} transparent>
            <OldEnrollmentsTable
              rows={data?.result ?? []}
              loading={isFetching || isLoading}            
            />          
          </Card>
          {!isFetching && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              onChangeRowsPerPage={(event) => {
                setPageSize(Number(event.target.value));
                setPagination(getPagination(Number(event.target.value)));
              }}
              pagination={pagination}
              onChangePage={(event, currentPageIndex) =>
                setPagination((prevPagination) => ({
                  ...prevPagination,
                  currentPageIndex,
                }))
              }
            />
          )}
        </Grid>
      </Grid>
    </Content>
  );
};