import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { MassEnrollResponse } from 'CertificationList/model/MassEnrollResponse';
import { PlanRequirements } from 'Requirement/model/PlanRequirements';
import { CertificationPlanHistory } from 'CertificationPlansPage/model/CertificationPlanHistory';
import { CertificateShortDetails, CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';

const domainPath = '/certification-list';

const getRootPath = (certificationId: number): string =>
  `${domainPath}/${certificationId}`;

const get = async (
  certificationId: number
): Promise<CertificationPlansDetails> => {
  try {
    const response = await apiClient.get(getRootPath(certificationId));
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getShort = async (
  certificateId: number
): Promise<CertificateShortDetails> => {
  try {
    const response = await apiClient.get(
      `${getRootPath(certificateId)}/short`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getRequirements = async (
  certificationId: number
): Promise<PlanRequirements> => {
  try {
    const response = await apiClient.get(
      `${getRootPath(certificationId)}/requirements`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getHistory = async (
  certificationId: number
): Promise<CertificationPlanHistory[]> => {
  try {
    const response = await apiClient.get(
      `${getRootPath(certificationId)}/history`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getEnrollRequest = async (
  certificationId: number
): Promise<CertificationPlansDetails> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/enroll-request/${certificationId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const publish = async (certificationId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${certificationId}/publish`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const rejectPublish = async (certificationId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${certificationId}/reject-publish`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface EnrollDto {
  enrollBehalfUser?: boolean;
  behalfUserId?: number;
}
const enroll = async (
  certificationId: number,
  enrollDto: EnrollDto = {}
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${certificationId}/enroll`,
      enrollDto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface MassEnrollDto {
  usersIds: number[];
  certificationIds: number[];
  
  notificationSubject?: string;
  notificationMessage?: string;
  additionalRecipients?: string[];
  dueDate: string | null;
}
const massEnroll = async (massEnrollDto: MassEnrollDto): Promise<MassEnrollResponse> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/mass-enroll`,
      massEnrollDto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface MassReminderDto {
  planIds: number[];
  
  notificationSubject?: string;
  notificationMessage?: string;
  additionalRecipients?: string[];
}

const sendReminder = async (dto: MassReminderDto): Promise<MassEnrollResponse> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/mass-remind`,
      dto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const certificationListApi = {
  get,
  getShort,
  getHistory,
  getRequirements,
  getEnrollRequest,
  enroll,
  massEnroll,
  sendReminder,
  publish,
  rejectPublish,
};
