import React, { useEffect, useState } from 'react'
import {
    EditorFormActions,
    Typography,
    Button,
    FluidForEditors,
    Stack
} from 'components'
import { Form, Formik, FormikTextField, Yup } from 'components/FormikField';
import { FormikProps, useField } from 'formik';

import * as API from 'Dictionary/service/dictionary.api';
import { useQuery } from 'react-query';
import { AutocompleteField } from 'components/Field';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { PreferredLanguagesSelectField } from 'Dictionary/component/PreferredLanguagesSelectField/PreferredLanguagesSelectField';

import * as Model from './model'

type DTOModel = Model.PrintingStandardTextDTO

const defaultNew = {} as unknown as DTOModel

export default function Editor(p: {
    item: DTOModel
    onCancel: React.DispatchWithoutAction
    onCommit: React.Dispatch<DTOModel>
}) {

    const toEdit = p.item
    const toEditDivision: DictionaryItemByCodeDTO = { id: toEdit.division.id, code: toEdit.division.id, display: toEdit.division.description }
    const doPreview = (forExternal: boolean) => {
        API.dictionaryApi
            .IPrintingCertificatesContract
            .preview({ standard: toEdit!, forExternal })
    }

    ///
    ///
    const onCancelAdd = () => p.onCancel()
    const onUpdateRow = (item: DTOModel) => p.onCommit(item)

    ///
    ///
    function renderForm(formikProps: FormikProps<DTOModel>) {
        const isNew = !toEdit.id
        return (
            <Form>
                <Stack>

                    <FluidForEditors inRow={1} >
                        <PreferredLanguagesSelectField disabled={!isNew} multiple={false} name="language" label="Language" />
                    </FluidForEditors>


                    <FluidForEditors inRow={1} >
                        <FluidForEditors inRow={2}>
                            <>
                                <Typography variant="h6">
                                    ABB People Text before product line
                                </Typography>
                                <FormikTextField name="textBeforeProductLine_ABB" multiline rows={4} inputProps={{ style: { paddingTop: 10 }}} />

                                <Typography variant="h6">
                                    ABB People Text after product line
                                </Typography>
                                <FormikTextField name="textAfterProductLine_ABB" multiline rows={4} inputProps={{ style: { paddingTop: 10 }}} />

                                <Typography variant="h6">
                                    ABB People signers
                                </Typography>
                                <SignerSelectField name='firstSignature_ABB' label="First signer" division={toEditDivision} />
                                <SignerSelectField name='secondSignature_ABB' label="Second signer" division={toEditDivision} />
                                <Button disabled={isNew} type="button" onClick={() => doPreview(false)}>Preview</Button>
                            </>

                            <>
                                <Typography variant="h6">
                                    Partners Text before product line
                                </Typography>

                                <FormikTextField name="textBeforeProductLine_External" multiline rows={4} inputProps={{ style: { paddingTop: 10 }}} />

                                <Typography variant="h6"  >
                                    Partners Text after product line
                                </Typography>
                                <FormikTextField name="textAfterProductLine_External" multiline rows={4} inputProps={{ style: { paddingTop: 10 }}}/>

                                <Typography variant="h6"  >
                                    Partners signers
                                </Typography>
                                <SignerSelectField name='firstSignature_External' label="First signer" division={toEditDivision} />
                                <SignerSelectField name='secondSignature_External' label="Second signer" division={toEditDivision} />
                                <Button disabled={isNew} type="button" onClick={() => doPreview(true)}>Preview</Button>
                            </>
                        </FluidForEditors>
                    </FluidForEditors>

                    <FluidForEditors inRow={1}>
                        <Typography variant="h6">Header</Typography>
                        <FluidForEditors inRow={2}>
                            <FormikTextField name="titleText" label='Title Text' />
                            <FormikTextField name="awardedText" label='Awarded Text' />

                        </FluidForEditors>
                        <FormikTextField name="divisionText" label='Division Text' />
                    </FluidForEditors>

                    <FluidForEditors inRow={1}>
                        <Typography variant="h6">Footer</Typography>
                        <FluidForEditors inRow={2}>
                            <FormikTextField name="dateText" label='Date Text' />
                            <DateFormatField name="dateFormat" label='Date Format' />
                        </FluidForEditors>
                    </FluidForEditors>
                </Stack>
                <EditorFormActions isNew={!formikProps.values.id} formik={formikProps} onCancelAdd={onCancelAdd} />
            </Form>
        )
    }
    const formValidationSchema = Yup.object().shape({
        language: Yup.object().required('Select language'),
        firstSignature_ABB: Yup.object().required('Select signer'),
        secondSignature_ABB: Yup.object().required('Select signer'),
        firstSignature_External: Yup.object().required('Select signer'),
        secondSignature_External: Yup.object().required('Select signer'),
        dateFormat: Yup.number().required('Select format'),
    });

    if (!toEdit) return (<></>)
    return (
        <Formik
            initialValues={toEdit}
            validationSchema={formValidationSchema}
            onSubmit={onUpdateRow}
        >
            {(formikProps) => renderForm(formikProps)}
        </Formik>)
}


const DateFormatField = (p: { name: string, label: string }) => {
    const [field, meta, helpers] = useField({ name: p.name });

    const { data = [], isLoading, isFetching, refetch } = useQuery<AutocompleteFieldItem[]>(
        ["printing-DateFormatField"],
        () => API.dictionaryApi.other.findDateFormats()
            .then(r => r.map(x => ({
                id: x.id,
                name: x.value
            }))
            ),
        { enabled: false, useErrorBoundary: false }
    )

    useEffect(() => {
        refetch()
    }, [])
    const selected = data.find(x => x.id == field.value)

    return (
        <>
            <AutocompleteField
                label={p.label}
                value={selected ?? {}} required disableClearable
                options={data}
                multiple={false}
                error={!!meta.error}
                helperText={meta.error}
                loading={isLoading || isFetching}
                onChange={(e, v) => helpers.setValue((v as AutocompleteFieldItem).id)}
            />
        </>
    )
}

const SignerSelectField = (p: { name: string, label: string, division: DictionaryItemByCodeDTO }) => {
    const searchTimeout = 100
    const minLenOfSearchValue = 1
    const initialValue = ""

    const [field, meta, helpers] = useField({ name: p.name });
    const [searchFor, setSearchFor] = useState(initialValue)
    const { data = [], isLoading, isFetching, refetch } = useQuery<Model.DigitalSignatureLookupDto[]>(
        ["printing-dtandard-signers"],
        () => API.dictionaryApi.digitalSignature
            .find({ currentPageIndex: 0, pageSize: 999, division: p.division })
            .then(r => r.result
                .map(x => ({
                    id: x.id,
                    signer: x.signer
                }))
            ),
        { enabled: false, useErrorBoundary: false }
    )

    useEffect(() => {
        if (initialValue !== searchFor && searchFor.length >= minLenOfSearchValue) {
            const timer = setTimeout(refetch, searchTimeout)
            return () => clearTimeout(timer)
        }
        return undefined;
    }, [searchFor])

    const signersMapper = data.map(item => ({
        id: item.id,
        name: `${item.signer.lastName}, ${item.signer.firstName}`,
    }))

    const selectedSigner: { id: number, name: string } | undefined = (field.value ? (signersMapper.find(x => x.id == field.value.id) ?? {
        id: field.value.id,
        name: `${field.value.signer.lastName}, ${field.value.signer.firstName}`,
    }) : undefined)


    const signers = selectedSigner && !signersMapper.find(x => x.id == selectedSigner.id) ? [...signersMapper, selectedSigner] : signersMapper

    return (
        <>
            <AutocompleteField
                label={p.label}
                required
                defaultValue={selectedSigner}
                options={signers}
                multiple={false}
                error={!!meta.error}
                helperText={meta.error}
                disableClearable
                loading={isLoading || isFetching}
                onInputChange={(event, eventValue) => setSearchFor(eventValue)}
                onChange={(e, v) => helpers.setValue(v)}
                onOpen={() => refetch()}
            />


        </>
    )
}