import { useQuery, UseQueryOptions } from 'react-query';
import { mapPaginationDto, PaginatedList, Pagination } from 'model';
import { oldEnrollmentsApi } from 'OldEnrollments/service/oldEnrollments.api';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';
import { UserSearchFilters } from 'MyProfile/model/UserSearchFilters';
import { OldEnrollmentDetailsResult, OldEnrollmentResult } from '../model/OldEnrollmentResult';

export const oldEnrollmentsKey = 'old-enrollments';
export const useOldEnrollmentsQuery = (
  pagination: Pagination,  
  filters: Partial<CatalogSearchFilters>,
  options: UseQueryOptions<PaginatedList<OldEnrollmentResult>>
) =>
  useQuery<PaginatedList<OldEnrollmentResult>>(
    [oldEnrollmentsKey, mapPaginationDto(pagination), filters],
    () => oldEnrollmentsApi.search(mapPaginationDto(pagination), filters),
    options
  );

export const oldEnrollmentsDetailsKey = 'old-enrollments-details';
export const useOldEnrollmentsDetailsQuery = (
  certificateId: number,
  filters: Partial<UserSearchFilters>
) =>
  useQuery<OldEnrollmentDetailsResult>([oldEnrollmentsDetailsKey, certificateId, filters], () =>
    oldEnrollmentsApi.searchDetails(certificateId, filters)
  );
