import { makeDefaultApi } from 'Dictionary/service/dictionary.api';
import { EnrollmentResponse } from 'CertificationList/model/MassEnrollResponse';
import { API as CAPI, CertificateListLookupDTO, PaginationDto } from '../Certificate/model';

export const API =
{
    ITrainingSetContract: {
        ...makeDefaultApi<TrainingSetDTO, TrainingSetFindQueryDTO>({ basePath: "admin/training-set/" }),
    },
    dictionaryQuery: (query: {
        key: KnowDictionaryKey,
        businessArea?: DictionaryItemByCodeDTO,
        division?: DictionaryItemByCodeDTO,
        divisions?: DictionaryItemByCodeDTO[],
        scope?: DictionaryItemByCodeDTO
    }) => CAPI.ICertificateListContract.dictionaryQuery(query)
}

export interface TrainingSetFindQueryDTO extends PaginationDto {
    name?: string,
    division?: DictionaryItemByCodeDTO,
    functionalJobFamily?: DictionaryItemByCodeDTO,
    functionalJob?: DictionaryItemByCodeDTO,
    country?: DictionaryItemByCodeDTO,
    userType?: DictionaryItemByCodeDTO
}

export interface TrainingSetDTO {
    id: number;
    isActive: boolean;
    name: string;
    createdOn: string;
    editedOn: string;
    forUserType: DictionaryItemByCodeDTO;
    forCountry: DictionaryItemByCodeDTO[];
    forDivision: DictionaryItemByCodeDTO[];
    forProductGroup: DictionaryItemByCodeDTO[];
    forFunctionalJob: DictionaryItemByCodeDTO[];
    forFunctionalJobFamily: DictionaryItemByCodeDTO[];
    certificates: CertificateListLookupDTO[];
    divisions: DictionaryItemByCodeDTO[];

    reagisterAllUsers: boolean;
    automaticRenewal: boolean;
}

export interface TrainingSetUpdateResultDTO{
    usersEnrolled: number;
    success: EnrollmentResponse[];
    failed: EnrollmentResponse[];
}

