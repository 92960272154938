import { Card, FluidForEditors, DictionaryField, FormikTextField, DictionaryFieldByCode, EditorHelper } from 'components';
import { API, CertificateListDefinitionDTO, KnownCertificateListStatus } from './model';

export type DTOModel = CertificateListDefinitionDTO

export function HeaderDataCardPart(p: { helper: EditorHelper<Partial<DTOModel>>; }) {
    const data = p.helper.values;
    const editable =  p.helper.values.status?.code === KnownCertificateListStatus.Temporary || p.helper.values.status?.code === KnownCertificateListStatus.Draft;

    return (
        <Card>
            <FluidForEditors inRow={1}>
                <FormikTextField required name="title" label="Title" disabled={!editable}/>
            </FluidForEditors>

            <FluidForEditors inRow={2}>
                <DictionaryField required name="businessArea" label="Business Area" query={API.ICertificateListContract.dictionaryQuery({ key: 'BusinessArea' })} dependandFieldName="division|productGroup|scopeDetail" disabled={!editable}/>
                <DictionaryFieldByCode required name="division" label="Division" query={{ key: 'Division', businessArea: data.businessArea }} disabled={!data.businessArea || !editable} dependandFieldName="productGroup|scopeDetail" />
                <DictionaryField name="productGroup" label="Product Group" query={API.ICertificateListContract.dictionaryQuery({ key: 'ProductGroup', division: data.division })} disabled={!data.division || !editable}/>
                <DictionaryField required name="level" label="Level" query={API.ICertificateListContract.dictionaryQuery({ key: 'Level' })} disabled={!editable}/>
                <DictionaryField required name="scope" label="Scope" query={API.ICertificateListContract.dictionaryQuery({ key: 'Scope', division: data.division })} disabled={!editable} dependandFieldName="scopeDetail" />
                <DictionaryField multiple required name="scopeDetail" label="Scope Detail" query={API.ICertificateListContract.dictionaryQuery({ key: 'ScopeDetail', division: data.division, scope: data.scope })} disabled={!data.scope || !data.division || !editable}/>
                <DictionaryField required name="mainFunction" label="Main Function" query={API.ICertificateListContract.dictionaryQuery({ key: 'MainFunction' })} disabled={!editable}/>
                <DictionaryField multiple name="validFor" label="Valid For" query={API.ICertificateListContract.dictionaryQuery({ key: 'ValidFor' })} />
                <DictionaryField multiple name="workType" label="Work Type" query={API.ICertificateListContract.dictionaryQuery({ key: 'WorkType' })} />
                <DictionaryField name="ownerCountry" label="Owner Country" query={API.ICertificateListContract.dictionaryQuery({ key: 'Country'})} blockSort />
                <FormikTextField name="duration" placeholder="HH:MM" label="Estimated Certificate Duration (HH:MM)" type="string" />
            </FluidForEditors>
            <FluidForEditors inRow={1}>
                <FormikTextField name="purpose" label="Purpose" multiline rows={4} inputProps={{ style: { paddingTop: 10 }}} />
            </FluidForEditors>
        </Card>

    );
}
