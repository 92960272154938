import { useState, useEffect } from 'react';

import { DataTable, Loader } from 'components';
import * as Model from './model';

export function PageRowView(p: { id: number; }) {

  const [state, setState] = useState<{ item: Model.TrainingSetDTO; loadedOn: number; } | undefined>();

  function loadData() {

    Model.API.ITrainingSetContract
      .get(p.id)
      .then(x => {
        setState({ item: x, loadedOn: new Date().getTime() });
      });
  }

  ///
  /// Effects
  useEffect(loadData, []);

  ///
  ///
  if (!state)
    return <Loader />;
  return (
    <DataTable
      columns={[
        { field: 'title', headerName: "Certificates", width: "35%" },
        { field: 'division', headerName: "Division", renderField: (r) => r.division.display, width: "20%" },
        { field: 'level', headerName: 'Level', renderField: (r) => r.level.display, width: "auto" },
        { field: 'publishDate', headerName: "Published", renderField: (r) => DataTable.formatDate(r.publishDate), width: "auto" },
      ]}
      noDataMessage="No certificates added"
      rows={(state.item.certificates).sort((a, b) => (a.title).localeCompare(b.title))}
      hideCollapseColumn />
  );
}
